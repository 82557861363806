import { AdvancedTargetingData } from '@openx/types';

import { DimensionChipLine, TargetingReadOnly } from '../shared';
import { TargetingReadOnlyProps } from '../types';

import { CustomVariablesItems } from './CustomVariables/CustomVariablesItems';
import { useGetContextualSegmentsComponents } from './hooks/useGetContextualSegmentsComponents';

export const AdvancedTargetingReadOnly = ({
  name,
  onOpenDrawerClick,
  onRemoveClick,
  targetingParams,
  isDisabled,
  readonly,
  accountUid,
}: TargetingReadOnlyProps<AdvancedTargetingData>) => {
  const { ContextualSegmentsItems, segmentsV2 } = useGetContextualSegmentsComponents();

  const hasContextual = !!targetingParams?.contextual?.length;
  const hasCustom = !!targetingParams?.custom?.length;
  const displayDimensionChip = segmentsV2 && hasContextual && hasCustom && targetingParams?.op;

  return (
    <TargetingReadOnly
      name={name}
      isEmpty={!targetingParams}
      onOpenDrawerClick={onOpenDrawerClick}
      onRemove={onRemoveClick}
      readonly={readonly}
      isDisabled={isDisabled}
    >
      {hasContextual && (
        <ContextualSegmentsItems readonly targetingParams={targetingParams} accountUid={accountUid as string} />
      )}

      {displayDimensionChip && <DimensionChipLine targetingDimension={targetingParams.op} />}

      {hasCustom && <CustomVariablesItems readonly targetingParams={targetingParams} />}
    </TargetingReadOnly>
  );
};
