import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core/lib/Loader/Loader';
import { Intersect, type VideoFormState, VideoOption, type VideoStatePayload } from '@openx/types';

import { useTargetingContext } from '../../utils';
import { SelectedItems, Switch, FiltersTitle } from '../shared';

interface Props {
  readonly?: boolean;
  params: VideoStatePayload;
  onChange?: (newData: VideoFormState | ((prevState: VideoFormState) => VideoFormState)) => void;
  type: VideoOption;
}

export const Items = ({ params, type, readonly = true, onChange = () => {} }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { op, val } = params;
  const { useVideoOptionsFetch } = useTargetingContext();
  const { options, isLoading } = useVideoOptionsFetch(type, !!val.size);

  const typeLabel = op === Intersect.NOT_INTERSECTS ? t('Excluded') : t('Included');
  const filtersTitle = val.size ? typeLabel : '';

  const switchState = op === Intersect.NOT_INTERSECTS;

  const handleChange = useCallback(
    (values: string[]) => {
      const selected = new Set(values);

      onChange(state => ({
        ...state,
        [type]: { op, val: selected },
      }));
    },
    [type, onChange, op]
  );

  const selectedItems = useMemo(() => [...val], [val]);

  const title = useMemo(
    () => ({
      [VideoOption.IN_STREAM_VIDEO_POSITION]: t('In-stream Video Position [ {count} ]', { count: val.size }),
      [VideoOption.MAX_AD_DURATION]: t('Maximum Ad Duration [ {count} ]', { count: val.size }),
      [VideoOption.PLACEMENT]: t('Placement [ {count} ]', { count: val.size }),
      [VideoOption.PRESENTATION_FORMAT]: t('Presentation Formats [ {count} ]', { count: val.size }),
    }),
    [t, val]
  );

  if (isLoading) return <Loader />;

  if (!val.size) return null;

  return (
    <div data-test="sub-section">
      <FiltersTitle onClear={() => handleChange([])} title={title[type]} readonly={readonly} />
      {!readonly && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            onChange(state => ({
              ...state,
              [type]: { op: op === Intersect.INTERSECTS ? Intersect.NOT_INTERSECTS : Intersect.INTERSECTS, val },
            }))
          }
          switchOffLabel={t('include')}
          switchOnLabel={t('exclude')}
          groupTitle={t('the following:')}
        />
      )}
      <SelectedItems
        filtersTitle={filtersTitle}
        dataTest={type.toLowerCase().replace(/\s/g, '-').replaceAll('_', '-')}
        isAddIcon={!switchState}
        selectedItems={selectedItems}
        readonly={readonly}
        handleChange={handleChange}
        options={options}
      />
    </div>
  );
};
