import { Chip, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Highlighter } from '@openx/types';
import { prepareHighlightRegExp, type HighlightPhraseProps } from '@openx/utils/lib/prepareHighlightedPhrase';

export interface MultiValueTableCellProps {
  values: string[];
  phrase?: HighlightPhraseProps;
  highlighter?: Highlighter;
  mapBy?: { [key: string]: { name: string } | undefined };
  showTooltip?: boolean;
  marginTop?: number;
}

const StyledChip = styled(Chip)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  padding: 0;
  color: ${({ theme }) => theme.palette.text.primary};
  background: ${({ theme }) => theme.palette.background.grayLight};

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.palette.text.disabled};
  }
`;

const StyledHighlightContainerSpan = styled('span')`
  float: left;
  max-width: 82%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export function MultiValueTableCell(props: MultiValueTableCellProps): JSX.Element | null {
  const { values: dataValues, mapBy, phrase = '', showTooltip = false, marginTop = 2 } = props;

  const values = mapBy ? dataValues.map(value => mapBy[value]?.name || value) : dataValues;
  const regexp = prepareHighlightRegExp(phrase);
  const matchedPhrase = values.find(value => value.match(regexp));
  const numberOfValues = values.length;
  const isSingleValue = numberOfValues === 1;
  const firstValue = values[0];
  const firstHighlightedValue = matchedPhrase || firstValue;
  const { highlighter = () => firstHighlightedValue } = props;

  if (isSingleValue) {
    return <StyledHighlightContainerSpan>{highlighter(firstValue)}</StyledHighlightContainerSpan>;
  }

  const tooltipTitle = showTooltip && (
    <span data-test="multi-value-tooltip-span">
      {values.slice(1).map((value, key) => (
        <p key={key}>{value}</p>
      ))}
    </span>
  );

  return (
    <>
      <StyledHighlightContainerSpan style={{ marginTop }}>
        {highlighter(firstHighlightedValue)}
      </StyledHighlightContainerSpan>
      <Tooltip title={tooltipTitle || ''}>
        <StyledChip
          size="small"
          label={showTooltip ? numberOfValues - 1 : numberOfValues}
          clickable
          data-test="multi-value-button"
        />
      </Tooltip>
    </>
  );
}

export default MultiValueTableCell;
