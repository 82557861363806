import { ChangeEvent, KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteVirtualize } from '@openx/components/core/lib/AutoComplete/AutoCompleteVirtualize';
import { ChipFilterValue, FilterOption, FilterConfig } from '@openx/types';

interface FilterItemsMenuProps {
  setCurrentFilter: (value: ChipFilterValue) => void;
  applyCurrentFilter: (value: ChipFilterValue) => void;
  currentValue: ChipFilterValue;
  filterConfig: FilterConfig;
  filteredEmptyOptions: FilterOption[];
  handleBlur: () => void;
  getOptionLabel: (option: FilterOption) => string;
  handleOnInputChange: (event: ChangeEvent<{}>, value: string) => void;
}

const filterOptions = (opts: FilterOption[], value: string): FilterOption[] => {
  const valueLower = value.toLowerCase();
  return opts.filter(opt => (opt.displayName || opt.value).toString().toLowerCase().includes(valueLower));
};

const getCustomValue = (inputValue: string | number, title = 'Any includes') => ({
  displayName: `${title}: ${inputValue}`,
  value: inputValue,
});

export function SelectFilter({
  setCurrentFilter,
  currentValue,
  filterConfig,
  filteredEmptyOptions,
  handleBlur,
  getOptionLabel,
  handleOnInputChange,
  applyCurrentFilter,
}: FilterItemsMenuProps) {
  const {
    options = [],
    loading,
    allowCustomValue,
    customValueTitle,
    onInputChange,
    noOptionsText,
    inputType,
    searchPhrase,
  } = filterConfig;

  const { t } = useTranslation();

  const initOption = useMemo(() => {
    if (!currentValue) {
      return null;
    }
    if (inputType === 'text') {
      return { value: currentValue };
    }
    return options.find(option => currentValue === option.value || currentValue === option.displayName) || null;
  }, [inputType, currentValue, options]);

  const [currentOption, setCurrentOption] = useState<FilterOption | null>(initOption);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && currentOption?.value) {
        applyCurrentFilter(currentOption.value);
      }
    },
    [currentOption, applyCurrentFilter]
  );

  const onChange = useCallback(
    (_, option) => {
      setCurrentOption(option);
      setCurrentFilter(option?.value || '');
    },
    [setCurrentFilter, setCurrentOption]
  );

  const onFreeSoloInputChange = useCallback(
    (event: ChangeEvent<{}>, value: string) => {
      if (event?.type !== 'change') {
        // call only on keyboard input
        return;
      }

      if (inputType === 'text') {
        setCurrentOption({ value });
        setCurrentFilter(value);
      }

      handleOnInputChange(event, value);
    },
    [setCurrentFilter, inputType, handleOnInputChange]
  );

  return (
    <AutoCompleteVirtualize<FilterOption, false, false, true>
      data-test="filter-list"
      textFieldProps={{
        placeholder: searchPhrase ?? t('Select or search...'),
      }}
      freeSolo={inputType === 'text' || undefined}
      options={filteredEmptyOptions}
      noOptionsText={noOptionsText || t('No options')}
      onBlur={handleBlur}
      getOptionLabel={getOptionLabel as (a: string | FilterOption) => string} // free solo allow string or FilterOption
      value={currentOption}
      loading={loading}
      disabled={!onInputChange && loading}
      onChange={onChange}
      onInputChange={onFreeSoloInputChange}
      onKeyDown={handleKeyDown}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        const filtered = filterOptions(options, inputValue);

        if (allowCustomValue && inputValue) {
          filtered.unshift(getCustomValue(inputValue, customValueTitle));
        }

        return filtered;
      }}
      renderOptions={{
        dataTest: 'filter-item',
        getOptionValue: option => option.displayName || option.value.toString(),
        getPaddingLevel: option => (option.padding ? option.padding : 0),
        tooltip: true,
      }}
    />
  );
}
