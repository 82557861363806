import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoCompleteVirtualize } from '@openx/components/core/lib/AutoComplete/AutoCompleteVirtualize';
import { CustomSizeInput } from '@openx/components/core/lib/CustomSizeInput/CustomSizeInput';
import { InventoryAndContentState, InventoryContentOption } from '@openx/types';

import { useTargetingContext } from '../../../../utils/context';
import { setComparisonOpValue } from '../../../shared/InventoryAndContent/inventoryHelpers';
import { TargetingItemsProps } from '../../../types';

const CUSTOM_ID = 'custom';

export const AdunitSizeDropdown = ({
  targetingParams,
  dispatchInventory = () => {},
}: TargetingItemsProps<InventoryAndContentState>) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');
  const [customEnabled, setCustomEnabled] = useState(false);
  const [open, setOpen] = useState(false);
  const { val } = targetingParams[InventoryContentOption.ADUNIT_SIZE];
  const { usePmpSizesFetch } = useTargetingContext();

  const { isSizesLoading, sizes } = usePmpSizesFetch();

  const sizesList = useMemo(() => {
    const CUSTOM_OPTION = {
      id: CUSTOM_ID,
      name: t('Add Custom Size'),
    };
    return [CUSTOM_OPTION, ...Object.values(sizes)];
  }, [t, sizes]);

  const filterOptions = useCallback(
    options => {
      return options.filter(opt => {
        return (
          (opt.name.includes(value) || opt.id === CUSTOM_ID) &&
          !targetingParams[InventoryContentOption.ADUNIT_SIZE].val.find(item => item === opt.id)
        );
      });
    },
    [targetingParams, value]
  );

  const onChange = useCallback(
    (value: { id: string }) => {
      if (value.id === CUSTOM_ID) {
        setCustomEnabled(true);
        setOpen(false);
        return;
      }
      dispatchInventory({
        payload: [...new Set([...val, value.id])],
        type: setComparisonOpValue[InventoryContentOption.ADUNIT_SIZE],
      });
    },
    [dispatchInventory, val]
  );

  return (
    <>
      <AutoCompleteVirtualize
        fullWidth
        textFieldProps={{ placeholder: t('[ Select an Ad Unit Size ]') }}
        disableClearable
        disableCloseOnSelect
        renderOptions={{
          dataTest: 'type-option',
        }}
        options={sizesList}
        getOptionLabel={option => option?.name || ''}
        filterOptions={filterOptions}
        onChange={(_, value) => onChange(value)}
        inputValue={value}
        data-test="adunit-size-dropdown"
        loading={isSizesLoading}
        disabled={isSizesLoading}
        onClose={() => {
          setOpen(false);
          setValue('');
        }}
        onInputChange={(e, value, reason) => {
          (reason === 'input' || reason === 'clear') && setValue(value);
        }}
        onOpen={() => setOpen(true)}
        value={undefined}
        open={open}
      />
      {customEnabled && (
        <CustomSizeInput onAdd={itemText => onChange({ id: itemText })} onCancel={() => setCustomEnabled(false)} />
      )}
    </>
  );
};
