import {
  SortDirection,
  ChangeCriteriaHandler,
  ChangeSingleCriteriaAction,
  PaginationCriteria,
  SortCriteria,
  TableCriteria,
  CriteriaChange,
  AllTableCriteria,
  CriteriaDimension,
  RowActionProps,
  BaseRow,
} from '@openx/types';

export function currentSort(column: string, sortConfig?: SortCriteria): SortDirection | undefined {
  if (!sortConfig || sortConfig.column !== column) {
    return undefined;
  }

  return sortConfig.direction;
}

export function hasPrevItems(paginationCriteria: PaginationCriteria): boolean {
  return paginationCriteria.pageNumber > 1;
}

export function maxPageNumber(paginationCriteria: PaginationCriteria): number {
  return Math.max(Math.ceil(paginationCriteria.totalCount / paginationCriteria.pageSize), 1);
}

export function hasNextItems(paginationCriteria: PaginationCriteria): boolean {
  return maxPageNumber(paginationCriteria) > paginationCriteria.pageNumber || paginationCriteria.hasMore === true;
}

export function prepareChangeCriteriaHandler<D extends CriteriaDimension, FiltersT extends {} = {}>(
  dimension: D,
  onChange?: ChangeCriteriaHandler<CriteriaDimension, FiltersT>
): ChangeSingleCriteriaAction<D> {
  return (newCriteria: TableCriteria<D>) => {
    onChange &&
      onChange({
        dimension,
        value: newCriteria,
      } as CriteriaChange<D, FiltersT>);
  };
}

export function hasToLoadMore(
  requestPageNumber: number,
  pagination?: TableCriteria<CriteriaDimension.PAGINATION>
): boolean {
  if (!pagination) {
    return false;
  }

  return requestPageNumber > maxPageNumber(pagination) && hasNextItems(pagination);
}

export function adjustPaginationCriteria<FiltersT extends {}>(
  criteria: AllTableCriteria<FiltersT>,
  totalCount: number,
  hasMore?: boolean
): AllTableCriteria<FiltersT> {
  const previousPaginationCriteria = criteria[CriteriaDimension.PAGINATION];

  if (!previousPaginationCriteria) {
    return criteria;
  }

  const pageNumber =
    hasMore === false
      ? Math.min(maxPageNumber({ ...previousPaginationCriteria, totalCount }), previousPaginationCriteria.pageNumber)
      : previousPaginationCriteria.pageNumber;

  return {
    ...criteria,
    [CriteriaDimension.PAGINATION]: {
      ...previousPaginationCriteria,
      hasMore,
      pageNumber,
      totalCount,
    },
  };
}

enum Tier1RowActionsTypes {
  DETAILS = 'DETAILS',
  RENAME = 'RENAME',
}

enum Tier2RowActionsTypes {
  ADD = 'ADD_',
}

enum Tier3RowActionsTypes {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}

enum Tier4RowActionsTypes {
  ACTIVATE = 'ACTIVATE',
  CLONE = 'CLONE',
  CREATE = 'CREATE_',
  DEACTIVATE = 'DEACTIVATE',
  EXPORT = 'EXPORT',
  IMPERSONATE = 'IMPERSONATE',
  LOGOUT = 'LOGOUT',
  OVERRIDE = 'OVERRIDE',
  PAUSE = 'PAUSE',
  PENDING = 'PENDING',
  REACTIVATE = 'REACTIVATE',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESUME = 'RESUME',
  SHOW = 'SHOW_',
  TROUBLESHOOT = 'TROUBLESHOOT',
  UNLOCK = 'UNLOCK',
  USE_AS_TEMPLATE = 'USE_AS_TEMPLATE',
}

enum Tier5RowActionsTypes {
  MANAGE_SIGNAL_CONTROL = 'MANAGE_SIGNAL_CONTROL',
  SHOW_SIMILAR = 'SHOW_SIMILAR',
}

enum TierRowActionTypes {
  TIER1 = 'TIER1',
  TIER2 = 'TIER2',
  TIER3 = 'TIER3',
  TIER4 = 'TIER4',
  TIER5 = 'TIER5',
}

type TierRowActions<T extends BaseRow> = {
  [TierRowActionTypes.TIER1]?: RowActionProps<T>[];
  [TierRowActionTypes.TIER2]?: RowActionProps<T>[];
  [TierRowActionTypes.TIER3]?: RowActionProps<T>[];
  [TierRowActionTypes.TIER4]?: RowActionProps<T>[];
  [TierRowActionTypes.TIER5]?: RowActionProps<T>[];
};

type GenericRowActions<T extends BaseRow> = Record<string, RowActionProps<T>>;

function applyCustomSortingCriteria<T extends BaseRow>(
  rowActions: GenericRowActions<T>,
  excludedValues?: RowActionProps<T>[]
): GenericRowActions<T>[] {
  const customSortingOrder: string[] = [
    Tier4RowActionsTypes.ACTIVATE,
    Tier4RowActionsTypes.DEACTIVATE,
    Tier4RowActionsTypes.PAUSE,
    Tier4RowActionsTypes.PENDING,
    Tier4RowActionsTypes.REACTIVATE,
    Tier4RowActionsTypes.RESUME,
    Tier4RowActionsTypes.UNLOCK,
    Tier4RowActionsTypes.CLONE,
    Tier4RowActionsTypes.USE_AS_TEMPLATE,
    Tier4RowActionsTypes.OVERRIDE,
    Tier4RowActionsTypes.IMPERSONATE,
    Tier4RowActionsTypes.TROUBLESHOOT,
    Tier4RowActionsTypes.RESET_PASSWORD,
    Tier4RowActionsTypes.LOGOUT,
  ];

  const sortedRowActions: GenericRowActions<T>[] = [];

  Object.keys(rowActions).forEach(key => {
    if (!excludedValues?.includes(rowActions[key])) {
      sortedRowActions.push({ [key]: rowActions[key] });
    }
  });

  return sortedRowActions.sort(function (a: GenericRowActions<T>, b: GenericRowActions<T>) {
    return customSortingOrder.indexOf(Object.keys(a)[0]) - customSortingOrder.indexOf(Object.keys(b)[0]);
  });
}

function categorizeRowAction<T extends BaseRow>(
  rowAction: GenericRowActions<T>,
  excludedValues?: RowActionProps<T>[]
): TierRowActions<T> {
  const sortedRowActions = applyCustomSortingCriteria(rowAction, excludedValues);

  const tiers: TierRowActions<T> = sortedRowActions.reduce((acc, action) => {
    const key = Object.keys(action)[0];

    switch (key) {
      case Tier1RowActionsTypes.RENAME:
      case Tier1RowActionsTypes.DETAILS:
        acc[TierRowActionTypes.TIER1] = [action[key], ...(acc[TierRowActionTypes.TIER1] || [])];
        break;
      case key.match(new RegExp(Tier2RowActionsTypes.ADD))?.input:
        acc[TierRowActionTypes.TIER2] = [...(acc[TierRowActionTypes.TIER2] || []), action[key]];
        break;
      case Tier3RowActionsTypes.DELETE:
      case Tier3RowActionsTypes.EDIT:
        acc[TierRowActionTypes.TIER3] = [action[key], ...(acc[TierRowActionTypes.TIER3] || [])];
        break;
      case Tier5RowActionsTypes.MANAGE_SIGNAL_CONTROL:
      case Tier5RowActionsTypes.SHOW_SIMILAR:
        acc[TierRowActionTypes.TIER5] = [...(acc[TierRowActionTypes.TIER5] || []), action[key]];
        break;
      default:
        // Tier4 is default
        acc[TierRowActionTypes.TIER4] = [...(acc[TierRowActionTypes.TIER4] || []), action[key]];
        break;
    }

    return acc;
  }, {} as TierRowActions<T>);

  return tiers;
}

export function sortMenuButtonItems<T extends BaseRow>(
  rowActions: GenericRowActions<T>,
  excludedValues?: RowActionProps<T>[]
): RowActionProps<T>[] {
  const tiers = categorizeRowAction(rowActions, excludedValues);

  const sortedActions: RowActionProps<T>[] = [];

  Object.values(TierRowActionTypes).forEach(tierType => {
    const tierActions = tiers[tierType];
    if (tierActions) {
      if (sortedActions.length > 0) {
        tierActions[0].topDivider = true;
      }
      sortedActions.push(...tierActions);
    }
  });

  return sortedActions;
}
