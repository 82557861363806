import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { GeoLocationsList, GeoLocationTypes } from '@openx/types';
import { produceSortedOptionsKeys } from '@openx/utils';

import { useTargetingContext } from '../../../utils/context';
import { BulkAddDrawer, InvalidItemsError } from '../../shared';
import { BULK_ADD_DEFAULT_COUNTRY, useBulkAddPlaceholder } from '../constants';
import { getDuplicatePostalCodes, validatePostalCodes } from '../utils';

interface Props {
  onClose: () => void;
  onBulkAdd: (items: GeoLocationsList) => void;
  currentItems: GeoLocationsList;
}

export function BulkAdd({ onClose, onBulkAdd, currentItems }: Props) {
  const { t } = useTranslation();
  const { useCountriesOptionsFetch, usePostalCodes } = useTargetingContext();
  const [selectedCountry, setSelectedCountry] = useState(BULK_ADD_DEFAULT_COUNTRY);
  const [validPostalCodes, setValidPostalCodes] = useState<GeoLocationsList>([]);

  const { countries, isCountriesLoading } = useCountriesOptionsFetch();

  const { fetchPostalCodes } = usePostalCodes();

  const bulkPlaceholder = useBulkAddPlaceholder();

  const selectedPostalCodes = useMemo(() => {
    return currentItems.reduce((acc, geoItem) => {
      if (geoItem.type === GeoLocationTypes.POSTAL_CODE && geoItem.postal_code && geoItem.country) {
        acc.add({ country: geoItem.country, postalCode: geoItem.postal_code });
      }

      return acc;
    }, new Set<{ country: string; postalCode: string }>());
  }, [currentItems]);

  const validate = useCallback(
    async (postalCodesInput: string[]) => {
      const errors: InvalidItemsError[] = [];

      const { uniquePostalCodes, duplicates } = getDuplicatePostalCodes(
        selectedPostalCodes,
        postalCodesInput,
        countries[selectedCountry]
      );

      const postalCodesApiResponse = uniquePostalCodes.size
        ? await fetchPostalCodes({
            country: countries[selectedCountry].name,
            postal_codes: [...uniquePostalCodes],
          })
        : [];

      setValidPostalCodes(postalCodesApiResponse);

      const { invalidPostalCodes, validPostalCodes } = validatePostalCodes(postalCodesApiResponse, uniquePostalCodes);

      if (duplicates.length)
        errors.push({
          error: t('Duplicates.'),
          invalidItems: duplicates,
        });

      if (invalidPostalCodes.length)
        errors.push({
          error: t('Invalid Postal Codes.'),
          invalidItems: invalidPostalCodes,
        });

      return { errors, validItems: validPostalCodes };
    },
    [countries, fetchPostalCodes, selectedCountry, selectedPostalCodes, t]
  );

  const onApply = useCallback(() => {
    onBulkAdd(validPostalCodes);
  }, [onBulkAdd, validPostalCodes]);

  const countriesList = useMemo(() => produceSortedOptionsKeys(countries), [countries]);

  return (
    <BulkAddDrawer
      onClose={onClose}
      onApply={onApply}
      placeholder={bulkPlaceholder}
      validate={validate}
      childParams={selectedCountry}
    >
      <AutoComplete
        textFieldProps={{ label: t('Country') }}
        options={countriesList}
        fullWidth
        loading={isCountriesLoading}
        renderOptions={{
          dataTest: 'type-option',
        }}
        disableClearable
        getOptionLabel={option => countries[option]?.name || option}
        onChange={(e, value) => setSelectedCountry(value)}
        value={isCountriesLoading ? '' : selectedCountry}
        data-test="countries-input"
      />
    </BulkAddDrawer>
  );
}
