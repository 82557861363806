import type { Segment } from '../segments';

import type { Intersect, ComparisonType } from './targetingValuesTypes';

export const audienceExpiredText = 'Audience hasn’t been refreshed in more than 90 days and is expired.';
export const audienceSoonWillBeExpiredText = 'Audience will expire in 10 days due to 90 day expiration window.';

export type AudienceMap = Record<string, { name: string; status?: string }>;

export const AudienceOption = {
  DMP_SEGMENTS: 'dmp_segments',
  INTENDED_AUDIENCE: 'audience_type',
  LIVEINTENT: 'liveintent',
  MATCHED_USERS: 'traffic_user_targeting_type',
  OPEN_AUDIENCES: 'openaudience_custom',
  TRAFFIC_FILTER_EIDS: 'traffic_filter_eids',
} as const;
// eslint-disable-next-line no-redeclare
export type AudienceOption = (typeof AudienceOption)[keyof typeof AudienceOption];

export type AudienceData = {
  [AudienceOption.DMP_SEGMENTS]?: Payload;
  [AudienceOption.INTENDED_AUDIENCE]?: Payload;
  [AudienceOption.LIVEINTENT]?: Payload;
  [AudienceOption.MATCHED_USERS]?: string;
  [AudienceOption.OPEN_AUDIENCES]?: Payload;
  [AudienceOption.TRAFFIC_FILTER_EIDS]?: Set<string>;
};

export type AudienceSegments = {
  segment: Pick<Segment, 'expiration_date' | 'id' | 'name' | 'status'>;
};

export type Audience = {
  audience_segments: AudienceSegments[];
  estimated_expiry_date?: string | null;
  id: string;
  name: string;
  status: string;
};

export interface Payload {
  op: Intersect.INTERSECTS | ComparisonType;
  val: string;
}

export const AudienceStatus = {
  ACTIVATED: 'activated',
  ACTIVATE_FAILED: 'activate_failed',
  DRAFT: 'draft',
  EXPIRED: 'expired',
  EXPIRE_FAILED: 'expire_failed',
  EXPIRING: 'expiring',
  EXPORTED: 'exported',
  EXPORTING: 'exporting',
  EXPORT_FAILED: 'export_failed',
  PUBLISHING: 'publishing',
  PUBLISH_FAILED: 'publish_failed',
} as const;
