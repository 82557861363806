import { BulkAction, type State } from './bulk';

const initVals = {
  errors: [],
  isResetEnabled: false,
  isValidating: false,
  validItems: [],
};

export const reducer = (state: State, action): State => {
  switch (action.type) {
    case BulkAction.SetValue:
      return { ...state, value: action.value };
    case BulkAction.SetErrors:
      return { ...state, errors: action.errors };
    case BulkAction.SetValidItems:
      return { ...state, validItems: action.validItems };
    case BulkAction.SetValidating:
      return { ...state, isValidating: action.isValidating };
    case BulkAction.SetResetEnabled:
      return { ...state, isResetEnabled: action.isResetEnabled };
    case BulkAction.InitValues:
      return {
        ...state,
        errors: action.errors ?? initVals.errors,
        isResetEnabled: action.isResetEnabled ?? initVals.isResetEnabled,
        isValidating: action.isValidating ?? initVals.isValidating,
        validItems: action.validItems ?? initVals.validItems,
        value: action.value,
      };
    default:
      return state;
  }
};
