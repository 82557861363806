import axios from 'axios';

import { OptionsList, OptionsType } from '@openx/types';

import { fetchApiResponseHandler } from '../../api/utils';

const maxAge = 24 * 60 * 60 * 1000;
const requestOptions = {
  headers: {
    'Cache-Control': `max-age=${maxAge}`,
  },
};

const optionsEndpoints = {
  [OptionsType.USER_ROLES]: '/options/user_role_options',
  [OptionsType.LOCALES]: '/options/locale_languages',
  [OptionsType.STATUSES]: '/options/status_options_common',
  [OptionsType.REPORT_RANGES]: '/options/report_range_options',
  [OptionsType.EXPERIENCES]: '/options/all_experience_options',
  [OptionsType.AD_CATEGORIES]: '/options/all_ad_category_options',
  [OptionsType.TARGETABLE_DEMAND_CRITERIA]: '/options/targetable_demand_criteria_options',
  [OptionsType.BUYERS]: '/options/buyer_options',
  [OptionsType.ACCOUNT_PARTNERS]: '/options/account_partner_options',
  [OptionsType.PARTNER_REGIONS]: '/options/partner_region_options',
  [OptionsType.COUNTRIES]: '/options/country_options',
  [OptionsType.MARKET_BRAND_GROUPS]: '/options/market_brand_group_options',
  [OptionsType.DOMAIN_CATEGORIES]: '/options/domain_category_type_options',
  [OptionsType.DOMAIN_AUDIENCE_TYPES]: '/options/audience_type_options',
  [OptionsType.DOMAIN_BAN_REASONS]: '/options/domain_banned_reasons_options',
  [OptionsType.CURRENCIES]: '/options/currency_options?sort=legacy_id',
  [OptionsType.ACCOUNTS]: '/options/account_options',
  [OptionsType.NETWORK_ACCOUNTS]: '/options/account_options?type_full=account.network',
  [OptionsType.NETWORK_N_PUBLISHERS_ACCOUNTS]:
    '/options/account_options?type_full__in=account.network,account.publisher',
  [OptionsType.PUBLISHERS_ACCOUNTS]: '/options/account_options?type_full=account.publisher',
  [OptionsType.ACTIVE_PUBLISHERS_ACCOUNTS]: '/options/account_options?type_full=account.publisher&status=Active',
  [OptionsType.ALL_DEMAND_PARTNERS]: '/options/all_demand_partner_options',
  [OptionsType.PMP_DEAL_TYPE]: '/options/pmp_deal_type_options',
  [OptionsType.DEAL_SOURCE]: '/options/deal_source_options',
  [OptionsType.TARGETABLE_DEMAND_PARTNER]: '/options/targetable_demand_partner_options',
  [OptionsType.OPEN_AUCTION_ACCESS]: '/options/open_auction_access_options',
  [OptionsType.CONTENT_ATTRIBUTE]: '/options/content_attribute_options',
  [OptionsType.CREATIVE_TYPES]: '/options/creative_type_options',
  [OptionsType.LANGUAGE]: '/options/language_options',
  [OptionsType.PMP_SIZE]: '/options/pmp_size_options',
  [OptionsType.DEVICE_TYPE]: '/options/device_type_options',
  [OptionsType.OS]: '/options/os_options',
  [OptionsType.APP_CATEGORIES_BAN_REASONS]: '/options/appcat_banned_reason_options',
  [OptionsType.APP_CATEGORIES_PLATFORMS]: '/options/appcat_platform_options',
  [OptionsType.APP_APPINFO_ORIGINS]: '/options/appinfo_origin_options',
  [OptionsType.VIDEO_LOCATION]: '/options/linearvideo_location_options',
  [OptionsType.VIDEO_FORMAT]: '/options/linearvideo_presentation_format_options',
  [OptionsType.VIDEO_DURATION]: '/options/linearvideo_duration_options',
  [OptionsType.VIDEO_PLACEMENT]: '/options/video_plcmt_options',
  [OptionsType.VIEW_THROUGH_RATE]: '/options/vtr_score_options',
  [OptionsType.VIEWABILITY]: '/options/targetable_viewabilityscore_options',
  [OptionsType.AUDIENCE_ACCOUNTS]: '/options/audience_account_options',
  [OptionsType.BROWSER]: '/options/browser_options',
  [OptionsType.TARGETING_LANGUAGE]: '/options/targeting_language_options',
  [OptionsType.SIZES]: '/options/size_options',
  [OptionsType.MOBILE_PRESENTATION_FORMAT]: '/options/mobile_presentation_format_options',
  [OptionsType.MOBILE_API_FRAMEWORK]: '/options/mobile_api_framework_options',
  [OptionsType.VIDEO_API_FRAMEWORK]: '/options/video_api_framework_options',
  [OptionsType.LINEARVIDEO_SIZE]: '/options/linearvideo_size_options',
  [OptionsType.ADUNIT_VIDEO_MIME_TYPE]: '/options/adunit_video_mime_type_options',
  [OptionsType.PLAYBACKMETHOD]: '/options/playbackmethod_options',
  [OptionsType.VIDEO_PROTOCOL]: '/options/video_protocol_options',
  [OptionsType.MOBILE_LOCATION]: '/options/mobile_location_options',
  [OptionsType.CONTENT_TOPIC]: '/options/content_topic_options',
  [OptionsType.DEAL_STATUS]: '/options/deal_status_options',
  [OptionsType.EMAIL_LOCATION]: '/options/email_location_options',
  [OptionsType.VIDEO_COMPANION_LOCATION]: '/options/videocompanion_location_options',
  [OptionsType.NON_LINEARVIDEO_LOCATION]: '/options/nonlinearvideo_location_options',
  [OptionsType.EXT_SUPPLY_PARTNER]: '/options/external_supply_partner_options',
  [OptionsType.PARTNER_TYPE]: '/options/partner_type_options',
  [OptionsType.AD_PLACEMENT]: '/options/ad_placement_options',
  [OptionsType.DISTRIBUTION_CHANNEL]: '/options/distribution_channel_options',
  [OptionsType.OPTIMIZATION_ACCOUNTS]: '/options/account_options?capabilities__contains=optimization.create',
  [OptionsType.MARKET_DELIVERY_MEDIUM]: '/options/market_delivery_medium_options',
  [OptionsType.FEE_REASON_OPTIONS]: '/options/fee_reason_options',
  [OptionsType.MARKET_OPERATORS]: '/options/market_operators',
  [OptionsType.MARKET_ADVERTISERS]: '/options/market_advertiser_options',
  [OptionsType.MARKET_BRANDS]: '/options/market_brand_options',
  [OptionsType.TRAFFIC_FILTER_EIDS]: '/options/eid_options',
  [OptionsType.SSRTB_METRICS]: '/options/metric_options',
  [OptionsType.SSRTB_ENRICHMENT_FEATURES]: '/options/enrichment_feature_options',
  [OptionsType.SSRTB_PREFERRED_TAXONOMY]: '/options/preferred_taxonomy_options',
  [OptionsType.TIMEZONE]: '/options/timezone_options',
  [OptionsType.COMPRESSION]: '/options/compression_options',
  [OptionsType.DATA_CENTER]: '/options/datacenter_options',
  [OptionsType.SEAT_REGIONS]: './options/seat_region_options',
  [OptionsType.METACATEGORIES]: '/options/metacategory_options',
  [OptionsType.GENRE]: '/options/targeting_genre_options',
  [OptionsType.CONTENT_RATING]: '/options/targeting_rating_options',
  [OptionsType.A_DOMAINS]: '/options/adomain_creativeinfo_options',
  [OptionsType.EXCHANGE_SIZE_OPTIONS]: '/options/exchange_size_options',
  [OptionsType.RTB_CONTENT_FORWARDING_DSP]:
    '/options/rtb_content_forwarding_dsp_options?premium_rtb_content_forwarding_dsp=true',
  [OptionsType.RTB_CONTENT_FORWARDING_OTHER_DSPS]:
    '/options/rtb_content_forwarding_dsp_options?premium_rtb_content_forwarding_dsp=false',
  [OptionsType.RTB_FORWARDING_POLICY_ALLOWED_SIGNALS]: '/options/rtb_forwarding_policy_allowed_signals',
  [OptionsType.SITES_WITH_ACCOUNT_INFO]: '/options/site_options?fields=account_id,id,name,account_uid',
  [OptionsType.LOCATION_SOURCE_OPTIONS]: '/options/location_source_options',
  [OptionsType.CONNECTION_SPEED]: '/options/connection_speed_options',
  [OptionsType.CONNECTION_TYPE]: '/options/connection_type_options',
  [OptionsType.DEVICE]: '/options/device_options',
  [OptionsType.SCREEN_RESOLUTION]: '/options/screen_resolution_options',
  [OptionsType.MOBILE_CARRIER]: '/options/isp_carrier_options',
  [OptionsType.DEAL_AD_CATEGORY]: '/options/deal_custom_pricing_ad_category',
  [OptionsType.CONTEXTUAL_PROVIDERS]: '/options/contextual_providers_options',
  [OptionsType.CRT_SENSITIVE_CATEGORY_TO_INDUSTRY_MAPPING]: '/options/crt_sensitive_industries_map_options',
};

export function fetchOptions(optionsType: OptionsType): Promise<OptionsList> {
  const requestUrl = optionsEndpoints[optionsType];

  return fetchApiResponseHandler(axios.get(requestUrl, requestOptions));
}
