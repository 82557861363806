import { Grid } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { AutoCompleteVirtualize } from '@openx/components/core/lib/AutoComplete/AutoCompleteVirtualize';
import { TextField } from '@openx/components/core/lib/TextField/TextField';
import { type AdvancedTargetingData, ComparisonType, type ContextualSegmentsV2FormState } from '@openx/types';

import { useGetContextualData } from '../hooks/useGetContextualData';

import { comparisonOptionLabels, filteredTypeOptions } from './constants';

interface ContextualSegmentsTopBoxItems extends AdvancedTargetingData {
  contextualV2: ContextualSegmentsV2FormState;
}

type ContextualSegmentsTopBoxProps = {
  items: ContextualSegmentsTopBoxItems;
  updateParams: Dispatch<SetStateAction<AdvancedTargetingData>>;
  accountUid: string;
};

export const ContextualSegmentsTopBoxV2 = ({ items, updateParams, accountUid }: ContextualSegmentsTopBoxProps) => {
  const { t } = useTranslation();
  const [segmentValue, setSegmentValue] = useState({ id: '', name: '' });
  const [comparisonType, setComparisonType] = useState<ComparisonType>();
  const optionLabels = useMemo(() => comparisonOptionLabels(), []);
  const { segments, loading, providerId, providerName } = useGetContextualData(accountUid);

  useEffect(() => {
    if (providerId) {
      updateParams(prev => ({
        ...prev,
        contextualV2: {
          ...prev.contextualV2,
          attr: providerId,
        },
      }));
    }
  }, [providerId, providerName, updateParams]);

  const selectedItems = useMemo(() => {
    const { includes = [], excludes = [] } = items?.contextualV2 ?? {};

    return [...includes, ...excludes];
  }, [items?.contextualV2]);

  const handleAddSegment = (_, value) => {
    const inclusionKey = comparisonType === ComparisonType.INTERSECTS ? 'includes' : 'excludes';

    updateParams(prev => {
      const prevSegmentValue = prev?.contextualV2?.[inclusionKey] || [];
      const udpatedSegments = [...prevSegmentValue, value.id];

      return {
        ...prev,
        contextualV2: {
          ...prev.contextualV2,
          attr: providerId,
          [inclusionKey]: udpatedSegments,
        },
      };
    });

    setSegmentValue({ id: '', name: '' });
  };

  const noSegments = segments.length === 0;
  const notApplicablePlaceholder = t('N / A');

  return (
    <>
      <Grid item xs={8} sx={{ paddingLeft: '0.8rem' }}>
        <Grid container spacing={1} sx={{ border: 'none', marginTop: theme => -theme.spacing(1) }}>
          <Grid item xs={6}>
            <TextField
              sx={{ width: '100%' }}
              name="contextual_provider"
              value={providerName}
              placeholder={providerName ?? notApplicablePlaceholder}
              label={t('Provider')}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <AutoComplete
              options={filteredTypeOptions}
              renderOptions={{
                dataTest: 'comparsion-option',
              }}
              getOptionLabel={option => optionLabels[option]}
              textFieldReadOnly
              disableClearable
              data-test={'comparison-options'}
              disabled={!providerName || noSegments || loading}
              textFieldProps={{
                label: t('Comparison Type'),
                placeholder: !providerName ? notApplicablePlaceholder : t('Select Segment'),
              }}
              onChange={(_, value) => setComparisonType(value)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ border: 'none', marginBottom: theme => theme.spacing(1), marginTop: theme => theme.spacing(1) }}
      >
        <AutoCompleteVirtualize
          loading={loading}
          disabled={!comparisonType || noSegments || loading}
          textFieldProps={{
            label: t('Segment'),
            placeholder: !providerName ? notApplicablePlaceholder : t('Select Segment'),
          }}
          onChange={handleAddSegment}
          getOptionLabel={option => option.name}
          filterOptions={options => options.filter(option => !selectedItems.includes(option.id))}
          options={segments}
          disableClearable
          data-test="contextual-segments-dropdown"
          value={segmentValue}
        />
      </Grid>
    </>
  );
};
