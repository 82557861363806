import { HighlightedPhrase } from '@openx/components/core/lib/HighlightedPhrase/HighlightedPhrase';

import { Tag, TagContainer, TagLabel } from './components';

type TagsProps = {
  tags: string[];
  phrase?: string;
  handleTagClick: (filters: { phrase: string }) => void;
  filled?: boolean;
  id?: string;
};

export function Tags({ tags, id = '', handleTagClick, phrase = '', filled = false }: TagsProps) {
  return (
    <TagContainer container>
      <TagLabel data-test="tags-label">{filled ? 'Popular tags:' : 'Tags:'}</TagLabel>
      {tags.map(tag => (
        <Tag data-test="tag-button" key={`${tag + id}`} onClick={() => handleTagClick({ phrase: tag })} filled={filled}>
          {filled ? <span>{tag}</span> : <HighlightedPhrase searchPhrase={phrase}>{tag}</HighlightedPhrase>}
        </Tag>
      ))}
    </TagContainer>
  );
}
