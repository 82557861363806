import { isEqual } from 'lodash';
import { useCallback, useState, useMemo, type SetStateAction, type Dispatch, useEffect } from 'react';

import { Loader } from '@openx/components/core/lib/Loader/Loader';
import {
  type InventoryAndContentApi,
  type InventoryAndContentState,
  TargetableInventoryOption,
  type InstanceProps,
  InventoryContentOption,
  Intersect,
} from '@openx/types';
import { usePrevious } from '@openx/utils';
import { ACL_TYPE, isAllowedByCompiledAcl, usePermissionContext } from '@openx/utils/permission';

import { useTargetingContext } from '../../utils';
import { CustomOptionsProvider } from '../../utils/hooks/useCustomOptionsContext';
import {
  inventoryAndContentDefaultOptions,
  inventoryApiToState,
  inventoryStateToApi,
  resetContent,
  setContent,
  TargetingDrawer,
  useDrawer,
} from '../shared';
import type { TargetingProps } from '../types';

import { useInventoryAndContent, useInstanceState } from './hooks';
import { InventoryAndContentItems } from './InventoryAndContentItems/InventoryAndContentItems';
import { InventoryAndContentReadOnly } from './InventoryAndContentReadOnly';
import { InventoryAndContentTopBox } from './InventoryAndContentTopBox';

export const InventoryAndContent = ({ targetingParams, ...props }: TargetingProps<InventoryAndContentApi>) => {
  const { useInventoriesFetch } = useTargetingContext();

  const { initState, inventoryPayload, idByInstances } = useMemo(() => {
    const initState = inventoryApiToState(targetingParams);
    const inventoryPayload = {
      excludes: { ...targetingParams?.excludes },
      includes: { ...targetingParams?.includes },
    };
    const idByInstances = (targetingParams?._idsByInstances && { ...targetingParams?._idsByInstances }) || null;
    return { idByInstances, initState, inventoryPayload };
  }, [targetingParams]);

  const { inventories, loading } = useInventoriesFetch(inventoryPayload, idByInstances);
  const [selectedInventory, setSelectedInventory] = useState<InventoryAndContentState | null>(null);

  const contentInventory = targetingParams &&
    inventories && {
      [InventoryContentOption.INVENTORY]: {
        [TargetableInventoryOption.INCLUDES]: inventories.includes,
        [TargetableInventoryOption.EXCLUDES]: inventories.excludes,
      },
    };

  if (loading) return <Loader />;

  //Render Inventory and Content with fetched data, renders initial state with fetched data
  return (
    <CustomOptionsProvider value={props.customOptions ?? inventoryAndContentDefaultOptions}>
      <RenderInventoryAndContent
        initState={{ ...initState, ...contentInventory, ...selectedInventory }}
        setSelectedInventory={setSelectedInventory}
        {...props}
      />
    </CustomOptionsProvider>
  );
};

const RenderInventoryAndContent = ({
  onFieldUpdate = () => {},
  name,
  readonly,
  field,
  isDisabled,
  initState,
  setSelectedInventory,
  accountUid,
  customOptions,
}: Omit<TargetingProps<InventoryAndContentApi>, 'targetingParams'> & {
  initState: InventoryAndContentState;
  setSelectedInventory: Dispatch<SetStateAction<InventoryAndContentState | null>>;
}) => {
  // default value added for OA, because it doesn't use useAccountFetch hook
  const { useCurrentInstanceState, useAccountFetch = () => {} } = useTargetingContext();

  const { inventoryState, dispatchInventory } = useInventoryAndContent(initState);
  const [inputInstanceValue, setInputInstanceValue] = useState<InstanceProps | null>(null);
  const { instanceState, dispatchInstanceState } = useInstanceState('');
  const { useIsAllowed } = usePermissionContext();

  const { isAllowed } = useIsAllowed();
  const isRoot = isAllowed(isAllowedByCompiledAcl(ACL_TYPE.ROOT_WORKAS_ANYINSTANCE));
  const isInternal = isAllowed(isAllowedByCompiledAcl(ACL_TYPE.INTERNAL_FIELD_WRITE));
  const hasMarketOpSearch = isAllowed(isAllowedByCompiledAcl(ACL_TYPE.MARKET_OPERATOR_SEARCH));

  const { isBefInstance } = useCurrentInstanceState();
  const isDataUpdated = useMemo(() => !isEqual(initState, inventoryState), [initState, inventoryState]);

  // account data are needed only for Site and Ad Unit targeting
  const shouldFetchAccountData =
    !!customOptions?.availableOptions.includes(InventoryContentOption.SITE) ||
    !!customOptions?.availableOptions.includes(InventoryContentOption.ADUNIT);
  const accountFetchHookPayload = useAccountFetch(shouldFetchAccountData ? accountUid : '');
  const { account, isAccountLoading } = accountFetchHookPayload || {};

  const parentAccountFeatures = useMemo(
    () => ({
      isAdUnitTargetingEnabled: !!account?.acl_override?.['advertiser.exchange.adunit_id_targeting.read'],
      isSiteTargetingEnabled: !!account?.acl_override?.['advertiser.exchange.site_id_targeting.read'],
    }),
    [account]
  );

  const previousAccountFeaturesValue = usePrevious(parentAccountFeatures);

  const { isOpen, onDrawerClose, onDrawerOpen, onDrawerApply } = useDrawer({
    onApply: () => {
      setSelectedInventory(inventoryState);
      onFieldUpdate(
        field,
        inventoryStateToApi(inventoryState, (isRoot || isInternal || hasMarketOpSearch) && isBefInstance)
      );
    },
    restoreParams: () => {
      setInputInstanceValue(null);
      dispatchInventory({ payload: initState, type: setContent });
    },
  });

  const onRemoveAll = useCallback(() => {
    onFieldUpdate(field, null);
    setSelectedInventory(null);
    dispatchInventory({ type: resetContent });
  }, [dispatchInventory, field, onFieldUpdate, setSelectedInventory]);

  useEffect(() => {
    if (parentAccountFeatures !== previousAccountFeaturesValue) {
      const isAdUnitTargetingEnabled = parentAccountFeatures['isAdUnitTargetingEnabled'];
      const isSiteTargetingEnabled = parentAccountFeatures['isSiteTargetingEnabled'];

      const updatedState = {
        ...inventoryState,
        [InventoryContentOption.ADUNIT]: isAdUnitTargetingEnabled
          ? inventoryState[InventoryContentOption.ADUNIT]
          : { op: Intersect.INTERSECTS, val: [] },
        [InventoryContentOption.SITE]: isSiteTargetingEnabled
          ? inventoryState[InventoryContentOption.SITE]
          : { op: Intersect.INTERSECTS, val: [] },
      };

      setSelectedInventory(updatedState);

      dispatchInventory({
        payload: updatedState,
        type: setContent,
      });
    }
  }, [setSelectedInventory, dispatchInventory, inventoryState, parentAccountFeatures, previousAccountFeaturesValue]);

  if (isAccountLoading) return <Loader />;

  return (
    <>
      <InventoryAndContentReadOnly
        name={name}
        isDisabled={isDisabled}
        readonly={readonly}
        targetingParams={initState}
        onOpenDrawerClick={onDrawerOpen}
        onRemoveClick={onRemoveAll}
      />
      {isOpen && (
        <TargetingDrawer name={name} onClose={onDrawerClose} onApply={onDrawerApply} isDataUpdated={isDataUpdated}>
          <InventoryAndContentTopBox
            targetingParams={inventoryState}
            dispatchInventory={dispatchInventory}
            dispatchInstance={dispatchInstanceState}
            inputInstanceValue={inputInstanceValue}
            setInputInstanceValue={setInputInstanceValue}
            isBefInstance={isBefInstance}
            parentAccountFeatures={parentAccountFeatures}
          />
          <InventoryAndContentItems
            targetingParams={inventoryState}
            readonly={false}
            dispatchInventory={dispatchInventory}
            dispatchInstance={dispatchInstanceState}
            instanceState={instanceState}
            inputInstanceValue={inputInstanceValue}
            isBefInstance={isBefInstance}
          />
        </TargetingDrawer>
      )}
    </>
  );
};
