import { useLocation } from 'react-router-dom';

import { useTargetingContext } from '../../../utils/context/TargetingContext';
import { ContextualSegmentsItems } from '../ContextualSegments/ContextualSegmentsItems';
import { ContextualSegmentsTopBox } from '../ContextualSegments/ContextualSegmentsTopBox';
import { ContextualSegmentsItemsV2 } from '../ContextualSegmentsV2/ContextualSegmentsItemsV2';
import { ContextualSegmentsTopBoxV2 } from '../ContextualSegmentsV2/ContextualSegmentsTopBoxV2';

const SEGMENTS_V2 = 'segmentsV2';

export const useGetContextualSegmentsComponents = () => {
  const { isOa } = useTargetingContext();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const segmentsV2Feature = params.get(SEGMENTS_V2) === 'true' && !!isOa;

  if (segmentsV2Feature) {
    return {
      ContextualSegmentsItems: ContextualSegmentsItemsV2,
      ContextualSegmentsTopBox: ContextualSegmentsTopBoxV2,
      segmentsV2: segmentsV2Feature,
    };
  }

  return {
    ContextualSegmentsItems: ContextualSegmentsItems,
    ContextualSegmentsTopBox: ContextualSegmentsTopBox,
    segmentsV2: segmentsV2Feature,
  };
};
