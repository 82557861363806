import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Theme, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { styled, css } from '@mui/material/styles';

import { COLORS } from '@openx/components/core/lib/theme/palette';
import { PaginationCriteria } from '@openx/types';
import { currentPageRange } from '@openx/utils/lib/tableCriteria';
import { hasNextItems, hasPrevItems } from '@openx/utils/lib/tableHelpers';

import { PaginationTextField } from './PaginationTextField';

const StyledPaginationContainerDiv = styled('div')`
  align-items: center;
  display: flex;
`;

const StyledTypography = styled(Typography)`
  margin-right: 10px;
`;

const sharedDisableFieldStyles = (theme: Theme, isFieldDisabled: boolean) => css`
  ${isFieldDisabled &&
  css`
    background: ${COLORS.BLACK008} !important;
    color: ${theme.palette.text.secondary} !important;
  `}
`;

const StyledChangePageIconButton = styled(IconButton)<{ disabled: boolean }>`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-color: ${({ theme }) => theme.palette.divider};
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  color: ${({ theme }) => theme.palette.text.secondary};
  height: ${({ theme }) => theme.spacing(4)};
  margin: ${({ theme }) => theme.spacing(0, 5 / 8)};
  padding: 0;
  width: ${({ theme }) => theme.spacing(4)};

  &:hover {
    background-color: ${({ theme }) => theme.palette.text.secondary};
    border: transparent;
    color: ${({ theme }) => theme.palette.common.white};
  }

  ${({ theme, disabled }) => sharedDisableFieldStyles(theme, disabled)}
`;

const StyledPaginationTextField = styled(PaginationTextField)<{ hasFieldDisableStyle: boolean }>`
  .MuiInput-input {
    height: ${({ theme }) => theme.spacing(4)};
    text-align: center;
    width: 49px;

    &:focus {
      border-color: ${({ theme }) => theme.palette.action.disabled};
    }

    :hover {
      background-color: ${({ theme }) => theme.palette.common.white};
      border-color: ${({ theme }) => theme.palette.action.disabled};
    }

    ${({ theme, hasFieldDisableStyle }) => sharedDisableFieldStyles(theme, hasFieldDisableStyle)}
  }
`;

export interface PaginationNavProps {
  paginationParams: PaginationCriteria;
  loading?: boolean;
  onChange: (paginationParams: PaginationCriteria) => void;
}

const PaginationNav = (props: PaginationNavProps): JSX.Element => {
  const { paginationParams, loading, onChange } = props;
  const canLoadPrevItems = !loading && hasPrevItems(paginationParams);
  const canLoadNextItems = !loading && hasNextItems(paginationParams);

  const pageRange = currentPageRange(
    Math.min(Math.ceil(paginationParams.totalCount / paginationParams.pageSize), paginationParams.pageNumber),
    paginationParams.pageSize,
    paginationParams.totalCount
  );

  const shiftPage = (shift: -1 | 1): void =>
    onChange({
      ...paginationParams,
      pageNumber: paginationParams.pageNumber + shift,
    });

  const onClickPrev = canLoadPrevItems ? () => shiftPage(-1) : undefined;
  const onClickNext = canLoadNextItems ? () => shiftPage(1) : undefined;

  return (
    <StyledPaginationContainerDiv>
      <StyledTypography variant="body1" data-test="number-of-pages">
        {pageRange.min} - {pageRange.max} of {paginationParams.hasMore && 'more than '}
        {paginationParams.totalCount}
      </StyledTypography>
      <StyledChangePageIconButton
        onClick={onClickPrev}
        disabled={!canLoadPrevItems}
        data-test="prev-page"
        disableRipple
        size="large"
      >
        <KeyboardArrowLeftIcon fontSize="small" />
      </StyledChangePageIconButton>
      <StyledPaginationTextField
        {...props}
        disabled={loading || (!canLoadPrevItems && !canLoadNextItems)}
        hasFieldDisableStyle={!canLoadPrevItems && !canLoadNextItems}
      />
      <StyledChangePageIconButton
        onClick={onClickNext}
        disabled={!canLoadNextItems}
        data-test="next-page"
        disableRipple
        size="large"
      >
        <KeyboardArrowRightIcon fontSize="small" />
      </StyledChangePageIconButton>
    </StyledPaginationContainerDiv>
  );
};

export default PaginationNav;
