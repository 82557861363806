import { KeyboardEvent, useCallback, useEffect, useState } from 'react';

import { TextField } from '@openx/components/core/lib/TextField/TextField';

interface Props {
  value: string;
  placeholder: string;
  onEnter: (value: string) => void;
}

export const LiveIntentInput = ({ value, onEnter, placeholder }: Props) => {
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const liveintentValue = inputValue ? inputValue.trim() : '';
        onEnter(liveintentValue);
      }
    },
    [inputValue, onEnter]
  );

  const onChange = useCallback(e => setInputValue(e.target.value), []);

  return (
    <TextField
      value={inputValue}
      onChange={onChange}
      onKeyUp={onKeyPress}
      fullWidth
      placeholder={placeholder}
      data-test="liveintent-input"
    />
  );
};
