import { Market } from './market';
import { ObjectType } from './objectTypes';
import { SellerJsonType } from './sellerType';

export const ACCOUNT_RESOURCE_TYPE_PREFIX = 'account';

export const ACCOUNT_TYPE_SEPARATOR = '.';

export type SellerJsonOverride = {
  override_seller_type: SellerJsonType;
  override_domain: string | null;
  override_name: string | null;
};

export enum AccountType {
  AD_NETWORK = 'account.network',
  PUBLISHER = 'account.publisher',
  ADVERTISER = 'account.advertiser',
  AD_AGENCY = 'account.agency',
  PARTNER = 'account.partner',
}

// this is not an api's type, it is used in UI for filtering purposes
export const MASTER_NETWORK = 'master.network';

export enum AccountStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum AccountExperience {
  NETWORK_DISPLAY = 'network.display',
  NETWORK_MOBILE = 'network.mobile',
  ADVERTISER_EXCHANGE = 'advertiser.exchange',
  ADVERTISER = 'advertiser',
  PUBLISHER = 'publisher',
  AGENCY = 'agency',
  PUBLISHER_SELF_SERVE = 'publisher.self_serve',
}

export const AccountDealPricingRuleType = {
  BANNER: 'Banner',
  CTV: 'CTV',
  VIDEO: 'Video',
} as const;
export type AccountDealPricingRuleTypeValues =
  (typeof AccountDealPricingRuleType)[keyof typeof AccountDealPricingRuleType];

export type AccountDealPricingRule = {
  ad_category: string | null;
  deal_rule_type: AccountDealPricingRuleTypeValues;
  price: string;
};

export interface Product {
  family: string;
  name: string;
  product: string;
  salesforce_id: string;
  status: string;
}

export interface AccountingInformation {
  city: string;
  country: string;
  salesforce_address_type: string;
  state: string;
  street: string;
  zip: string;
}

export type AccountPaymentsHistory = {
  amount: string;
  created_date: string;
  creator_email: string;
  creator_id: string;
  creator_uid: string;
  id: string;
  modified_date: string;
  notes: string;
};

export type AccountExchange = {
  blocked_adcategories?: Record<string, boolean>;
  blocked_adcategories_iab_v2?: Record<string, boolean>;
  domains?: Record<string, boolean>;
  ssrtb: {
    encryption_key?: string;
    integrity_key?: string;
    preferred_taxonomy: string;
    metrics?: Record<string, boolean>;
    enrichment_features?: Record<string, boolean>;
    header_object?: boolean;
    tid_enabled?: boolean;
    send_floors?: boolean;
    lurl_enabled?: boolean;
    send_multiformat_requests?: boolean;
    indium_chloride_partner_id?: string;
    compression?: RtbEndpointCompression;
  } | null;
  traffic_filter_eids?: Record<number, boolean>;
  skadnetwork_ids?: Record<string, boolean>;
  monthly_budget: string;
  prepaid_initial_budget: string;
  prepaid_budget_description: string;
  flags?: Record<string, boolean>;
};

export type Account = {
  id: string;
  name: string;
  type: ObjectType.Account;
  type_full: AccountType;
  uid: string;
  iab_tcf_global_vendor_list_id: string | number;
  compiled_acl: string;
  modified_date: string;
  status: AccountStatus;
  partner_type: string[];
  partner_id: string | null;
  partner_uid: string | null;
  instance_uid: string;
  products?: Product[];
  demand_oracle_info: {
    oracle_id: string | null;
    oracle_name: string | null;
  };
  supply_oracle_info: {
    oracle_id: string | null;
    oracle_name: string | null;
  };
  demand_legal_entity?: string;
  contact_email?: string;
  contact_name?: string;
  notes: string;
  accounting_info: AccountingInformation[];
  account_uid?: string | null;
  account_id: string | null;
  experience: AccountExperience;
  currency: string;
  country_of_business: string;
  contextual_providers: { [key: string]: true } | null;
  acl_override?: Record<string, boolean> | null;
  external_id?: string | null;
  dmp_sources?: string | null;
  acl?: AccEditableAcls;
  sellers_json: SellerJsonOverride;
  accept_bid_below_floor: boolean;
  third_party_partners: string[] | null;
  timezone?: string | null;
  timezone_id?: string | null;
  created_date: string;
  has_exchange?: boolean;
  exchange?: AccountExchange | null;
  has_ssrtb?: boolean;
  gross_bidding?: boolean;
  deal_custom_pricing_rules: AccountDealPricingRule[];
  exchange_account_network_currency?: string | null;
};

export type AccountTableData = {
  id: string;
  name: string;
  status: AccountStatus;
  account_type: string;
  managing_account: string;
};

export type EnrichedAccount = {
  market: Market | null;
} & Account;

export type AccEditableAcls = {
  'pmp.third_party_revenue.write'?: boolean;
  'floorrule.read'?: boolean;
  'pmp.read'?: boolean;
  'pmp.is_pub_visible.read'?: boolean;
  'pmp.is_pub_visible.write'?: boolean;
  'internal.ext_inventory_map.read'?: boolean;
  'dmp.read'?: boolean;
};

export type AccountACL = {
  acl?: AccEditableAcls;
};

export type AccountSimplified = AccountACL &
  Pick<
    Account,
    | 'id'
    | 'uid'
    | 'name'
    | 'type_full'
    | 'status'
    | 'account_id'
    | 'account_uid'
    | 'type'
    | 'instance_uid'
    | 'dmp_sources'
    | 'modified_date'
  > &
  Partial<Account>;

export interface AccountTree extends AccountSimplified {
  expanded?: boolean;
  children?: AccountTree[];
  depth?: number;
  customRowStyle?: Record<string, string>;
  parentName?: string;
  isFetching?: boolean;
  showArrowIcon?: boolean;
}

export type AccountsMap = Record<string, Account>;

export enum SellerType {
  RESELLER = 'RESELLER',
  DIRECT = 'DIRECT',
}

export enum AccountsChipFilters {
  type_full = 'type_full',
  status = 'status',
  account_uid = 'account_uid',
  instance_uid = 'instance_uid__in',
  experience = 'experience',
}

export enum RtbEndpointCompression {
  GZIP = 'gzip',
  NONE = 'none',
}

export type RtbEndpointApi = {
  account_uid: string;
  compression: RtbEndpointCompression;
  name: string;
  uid?: string;
  url: string;
  id: string;
};

export type LineItemEndpoint = {
  endpoint_id: string;
  colo: string;
  qps: string | number | null;
};

export type Timezone = {
  legacy_id: number;
  id: string;
  name: string;
};

export type ContextualProvider = {
  name: string;
  id: string;
};
