import { Grid } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import {
  AdPlacement,
  Criteria,
  DistributionChannel,
  FormatTypeData,
  Intersect,
  InventoryFormatTypeOption,
} from '@openx/types';
import { produceSortedOptionsKeys } from '@openx/utils';

import { useTargetingContext } from '../../utils';
import { DrawerTopBox } from '../shared';

import { FormatTypeGroupOptionDropdown } from './components/FormatTypeGroupOptionDropdown';

interface TopBoxProps {
  distributionChannel: DistributionChannel | null;
  adPlacement: AdPlacement | null;
  targetingParams: FormatTypeData | null;
  setDistributionChannel: (value: DistributionChannel | null) => void;
  setAdPlacement: (value: AdPlacement | null) => void;
  updateParams: (value: FormatTypeData | null) => void;
}

export const TopBox = ({
  targetingParams,
  distributionChannel,
  adPlacement,
  setAdPlacement,
  setDistributionChannel,
  updateParams,
}: TopBoxProps) => {
  const { t } = useTranslation();
  const { useAdPlacementOptions, useDistributionChannelOptions } = useTargetingContext();
  const { isAdPlacementLoading, adPlacement: adPlacementMap } = useAdPlacementOptions();
  const { isDistributionChannelLoading, distributionChannel: distributionChannelMap } = useDistributionChannelOptions();

  const [categoryType, setCategoryType] = useState<InventoryFormatTypeOption>(
    InventoryFormatTypeOption.DISTRIBUTION_CHANNEL
  );

  const distributionChannelOptionsList = useMemo(
    () => produceSortedOptionsKeys(distributionChannelMap),
    [distributionChannelMap]
  );
  const adPlacementOptionsList = useMemo(() => produceSortedOptionsKeys(adPlacementMap), [adPlacementMap]);

  const handleDistributionChannel = useCallback(
    (value: DistributionChannel[]) => {
      const convertedVal = value.filter(Boolean).join(',') as DistributionChannel;

      setDistributionChannel(convertedVal);
      updateParams({
        ...targetingParams,
        distribution_channel: { op: Intersect.INTERSECTS, val: convertedVal },
        op: Criteria.ALL,
      });
    },
    [setDistributionChannel, updateParams, targetingParams]
  );

  const handleAdPlacement = useCallback(
    (value: AdPlacement[]) => {
      const convertedVal = value.filter(Boolean).join(',') as AdPlacement;
      setAdPlacement(convertedVal);

      updateParams({
        ...targetingParams,
        ad_placement: { op: Intersect.INTERSECTS, val: convertedVal },
        op: Criteria.ALL,
      });
    },
    [updateParams, setAdPlacement, targetingParams]
  );

  const renderedOption = useMemo(() => {
    switch (categoryType) {
      case InventoryFormatTypeOption.DISTRIBUTION_CHANNEL:
        return (
          <AutoComplete
            textFieldProps={{
              label: t('Distribution Channel'),
              margin: 'dense',
              placeholder: t('[ Select Distribution Channel ]'),
            }}
            options={distributionChannelOptionsList}
            fullWidth
            loading={isDistributionChannelLoading}
            renderOptions={{
              dataTest: 'type-option',
              tooltip: true,
            }}
            getOptionLabel={option => (option ? distributionChannelMap[option]?.name : option ?? '')}
            onChange={(_, value) =>
              value ? handleDistributionChannel(value as DistributionChannel[]) : setDistributionChannel(null)
            }
            value={isDistributionChannelLoading ? [] : distributionChannel?.replaceAll(' ', '').split(',') ?? []}
            textFieldReadOnly
            multiple
            renderTags={() => null}
            data-test="distribution-channel"
          />
        );
      case InventoryFormatTypeOption.AD_PLACEMENT:
        return (
          <AutoComplete
            textFieldProps={{ label: t('Ad Placement'), margin: 'dense', placeholder: t('[ Select Ad Placement ]') }}
            options={adPlacementOptionsList}
            fullWidth
            loading={isAdPlacementLoading}
            renderOptions={{
              dataTest: 'type-option',
              tooltip: true,
            }}
            getOptionLabel={option => (option ? adPlacementMap[option]?.name : option ?? '')}
            onChange={(_, value) => (value ? handleAdPlacement(value as AdPlacement[]) : setAdPlacement(null))}
            value={isAdPlacementLoading ? [] : adPlacement?.replaceAll(' ', '').split(',') ?? []}
            textFieldReadOnly
            multiple
            renderTags={() => null}
            data-test="ad-placement"
          />
        );
    }
  }, [
    adPlacement,
    adPlacementMap,
    adPlacementOptionsList,
    categoryType,
    distributionChannel,
    distributionChannelMap,
    distributionChannelOptionsList,
    handleAdPlacement,
    handleDistributionChannel,
    isAdPlacementLoading,
    isDistributionChannelLoading,
    setAdPlacement,
    setDistributionChannel,
    t,
  ]);

  return (
    <DrawerTopBox>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4}>
          <FormatTypeGroupOptionDropdown value={categoryType} onChange={v => setCategoryType(v)} />
        </Grid>
        <Grid item xs={8}>
          {renderedOption}
        </Grid>
      </Grid>
    </DrawerTopBox>
  );
};
