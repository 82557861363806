import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useTargetingContext } from '../../../utils/context/TargetingContext';

export const useGetContextualData = (accountUid: string) => {
  const { useRelatedAccountsFetch, useFetchSegments } = useTargetingContext();
  const { relatedAccounts } = useRelatedAccountsFetch([]);
  const { t } = useTranslation();
  const location = useLocation();
  const application = location.pathname.includes('deals') ? 'Deal' : 'Package';

  const segmentsNotAvailableMessage = t(
    'Contextual Segments are not available under this account. To add them, choose an account with connected contextual provider in the On Behalf Of section of the {application} form.',
    { application }
  );

  const accountUidFromProps = accountUid || '';
  const contextualProvider = useMemo(() => {
    return Object.keys(relatedAccounts?.[accountUidFromProps]?.contextual_providers || [])[0];
  }, [accountUidFromProps, relatedAccounts]);

  const { segments, loading } = useFetchSegments({
    account_id: relatedAccounts?.[accountUidFromProps]?.id,
    contextual_provider: contextualProvider,
  });

  return {
    loading,
    providerId: contextualProvider,
    providerName: segments?.[0]?.provider_name,
    segments,
    segmentsNotAvailableMessage,
  };
};
