import { difference, isEqual } from 'lodash';

import {
  type AdvancedTargetingData,
  type AdvancedTargetingType,
  ComparisonType,
  type ContextualSegmentsV2FormState,
  Criteria,
  type CustomVariablesDataPayload,
  TargetingFields,
} from '@openx/types';

export const defaultState: AdvancedTargetingData = {
  contextual: [],
  contextualV2: {
    attr: '',
    excludes: [],
    includes: [],
    op: ComparisonType.INTERSECTS,
  },
  custom: [],
  op: Criteria.ALL,
};

export const getCurrentType = (targetingParams?: AdvancedTargetingData | null): AdvancedTargetingType | undefined => {
  if (targetingParams?.contextual?.length) {
    return TargetingFields.CONTEXTUAL_SEGMENTS;
  }

  if (targetingParams?.custom?.length) {
    return TargetingFields.CUSTOM_VARIABLES;
  }

  return undefined;
};

export const mapContextualToApi = (targetingParams: AdvancedTargetingData): CustomVariablesDataPayload[] => {
  const { contextualV2 } = targetingParams;
  const { attr, includes, excludes } =
    contextualV2 ||
    ({ attr: '', excludes: [], includes: [] } as { attr: string; excludes: string[]; includes: string[] });

  const contextual = [
    {
      attr,
      op: ComparisonType.INTERSECTS,
      val: includes,
    },
    {
      attr,
      op: ComparisonType.NOT_INTERSECTS,
      val: excludes,
    },
  ];

  return contextual.filter(({ val }) => val.length).map(({ attr, op, val }) => ({ attr, op, val: val.join(',') }));
};

export const mapContextualToFormState = (
  targetingParams: AdvancedTargetingData | null
): ContextualSegmentsV2FormState => {
  if (!targetingParams || !targetingParams?.contextual) {
    return {
      attr: '',
      excludes: [],
      includes: [],
      op: ComparisonType.INTERSECTS,
    };
  }

  const { contextual, op } = targetingParams;
  const [segment] = contextual;
  const attr = segment?.attr || '';

  const { includes, excludes } = contextual.reduce(
    (acc, item) => {
      if (item?.val) {
        if (item.op === ComparisonType.INTERSECTS) {
          acc.includes = item.val.split(',');
        } else {
          acc.excludes = item.val.split(',');
        }
      }

      return acc;
    },
    { excludes: [], includes: [] } as { excludes: string[]; includes: string[] }
  );

  return {
    attr,
    excludes,
    includes,
    op: op === Criteria.ANY ? ComparisonType.NOT_INTERSECTS : ComparisonType.INTERSECTS,
  };
};

export const getInitialState = (
  targetingParams: AdvancedTargetingData | null,
  type: AdvancedTargetingType | undefined,
  segmentsV2: boolean
): AdvancedTargetingData => {
  if (!segmentsV2) {
    return {
      ...defaultState,
      ...(type && {
        op: targetingParams?.op ?? defaultState.op,
        [type]: targetingParams ? targetingParams[type] : [],
      }),
    };
  }

  const contextualSegmentsState = mapContextualToFormState(targetingParams);

  return {
    contextual: targetingParams?.contextual ?? defaultState.contextual,
    contextualV2: contextualSegmentsState,
    custom: targetingParams?.custom ?? defaultState.custom,
    op: targetingParams?.op ?? defaultState.op,
  };
};

export const isContextualUpdatedCheck = (
  initState: AdvancedTargetingData,
  params: AdvancedTargetingData,
  segmentsV2: boolean
): boolean => {
  if (!segmentsV2) {
    const contextual = params.contextual ?? [];
    const contextualSegmentsWithIds = contextual.length
      ? contextual.filter(contextualSegment => contextualSegment.val)
      : [];
    const hasEmptySegmentIdsInputs = contextual.length !== contextualSegmentsWithIds.length;

    return !hasEmptySegmentIdsInputs && !isEqual(initState, params);
  }

  const { includes: includesInit, excludes: excludesInit } = initState.contextualV2 || { excludes: [], includes: [] };
  const { includes, excludes } = params.contextualV2 || {};

  return !!(difference(includes, includesInit).length || difference(excludes, excludesInit).length);
};
