import { useMemo } from 'react';

import { useTargetingContext } from '../../../utils/context/TargetingContext';

export const useGetContextualData = (accountUid: string) => {
  const { useRelatedAccountsFetch, useFetchSegments } = useTargetingContext();
  const { relatedAccounts } = useRelatedAccountsFetch([]);

  const accountUidFromProps = accountUid || '';
  const contextualProvider = useMemo(() => {
    return Object.keys(relatedAccounts?.[accountUidFromProps]?.contextual_providers || [])[0];
  }, [accountUidFromProps, relatedAccounts]);

  const { segments, loading } = useFetchSegments({
    account_id: relatedAccounts?.[accountUidFromProps]?.id,
    contextual_provider: contextualProvider,
  });

  return {
    loading,
    providerId: contextualProvider,
    providerName: segments?.[0]?.provider_name,
    segments,
  };
};
