import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type ProviderDetailsProps = {
  providerFromState: string;
  component: 'deal' | 'package';
  readonly?: boolean;
  providerId: string;
  providerName: string;
  shouldReplaceProvider: boolean;
};

export const ProviderDetails = ({
  providerFromState,
  component,
  readonly,
  providerId,
  providerName,
  shouldReplaceProvider,
}: ProviderDetailsProps) => {
  const { t } = useTranslation();

  const providerDisplayValue = readonly && providerId !== providerFromState ? providerFromState : providerName;

  if (!providerFromState) {
    return null;
  }

  return (
    <div data-test="provider">
      <Typography data-test="provider-title" sx={{ color: 'text.secondary' }}>
        {t('Provider')}
      </Typography>

      <Stack direction="row" alignItems="center" data-test="provider-value">
        {providerDisplayValue}
        {shouldReplaceProvider && (
          <Tooltip
            data-test={'changed-provider-tooltip'}
            title={t(
              'Warning: The provider linked to your account does not match the one originally assigned to this {component}. Saving will update the provider, potentially affecting performance. Contact OpenX Support if needed.',
              { component: component.toLocaleLowerCase() }
            )}
            placement="right"
          >
            <IconButton>
              <WarningIcon color="warning" />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </div>
  );
};
