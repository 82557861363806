import { useCallback } from 'react';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';

import { AllowBlockType } from '../../types';

type MetacategoryAllowBlockTypeDropdownProps = {
  allowBlockType: AllowBlockType;
  setAllowBlockType: (type: AllowBlockType) => void;
};

const options = Array.from(Object.values(AllowBlockType));

export const MetacategoryAllowBlockTypeDropdown = ({
  setAllowBlockType,
  allowBlockType,
}: MetacategoryAllowBlockTypeDropdownProps) => {
  const onChange = useCallback((_, val: AllowBlockType) => setAllowBlockType(val), [setAllowBlockType]);

  return (
    <AutoComplete
      options={options}
      value={allowBlockType}
      onChange={onChange}
      disableClearable
      textFieldReadOnly
      fullWidth
      data-test={'metacategory-intersect-type'}
    />
  );
};
