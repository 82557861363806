import { t } from 'i18next';

import type { ContextualSegmentsV2FormState } from '@openx/types';

import type { InvalidItemsError } from '../../shared';

export const validateBulkContextualSegments = async (
  addedItems: string[],
  contextualItems: ContextualSegmentsV2FormState
) => {
  const itemsOnList = new Set(contextualItems?.excludes.concat(contextualItems?.includes) ?? []);
  const duplicates: string[] = [];
  const validItems: string[] = [];
  const errors: InvalidItemsError[] = [];

  addedItems.forEach(item => {
    if (itemsOnList.has(item)) {
      duplicates.push(item);
    } else {
      validItems.push(item);
    }
  });

  const uniqueDuplicates = [...new Set(duplicates)];
  const uniqueValidItems = [...new Set(validItems)];

  if (uniqueDuplicates.length) errors.push({ error: t('Duplicates.'), invalidItems: uniqueDuplicates });

  return { errors, validItems: uniqueValidItems };
};
