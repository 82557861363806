import { omit, pick } from 'lodash';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { InventoryContentOption } from '@openx/types';

import { useCustomOptionsContext, useTargetingContext } from '../../../utils';

type InventoryContentOptionDropdownProps = {
  onChange: (type: InventoryContentOption) => void;
  value: InventoryContentOption;
  isBulk?: boolean;
  parentAccountFeatures: Record<string, boolean>;
};

export const InventoryContentOptionDropdown = (props: InventoryContentOptionDropdownProps) => {
  const { useCurrentInstanceState, isOa } = useTargetingContext();
  const customOptions = useCustomOptionsContext<InventoryContentOption>();
  const { onChange, value, isBulk = false, parentAccountFeatures } = props;
  const { t } = useTranslation();
  const { isBefInstance } = useCurrentInstanceState();

  let labels: Record<string, string> = {
    [InventoryContentOption.APP_BUNDLE_ID]: t('App bundle ID'),
    [InventoryContentOption.INVENTORY]: t('Inventory'),
    [InventoryContentOption.PAGE_URL]: t('Page URL'),
    [InventoryContentOption.ADUNIT]: t('Ad Unit ID'),
    [InventoryContentOption.ADUNIT_SIZE]: t('Ad Unit Size'),
    [InventoryContentOption.PUBLISHER_ID]: t('Publisher ID'),
    [InventoryContentOption.INSTANCE_HASH]: t('Instance Hash'),
    [InventoryContentOption.SITE]: t('Site ID'),
    [InventoryContentOption.KEYWORDS]: t('Keywords'),
    [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]: t('App bundle ID'),
  };
  let bulkOptions: Record<string, string> = {
    [InventoryContentOption.ADUNIT]: t('Ad Unit ID'),
    [InventoryContentOption.APP_BUNDLE_ID]: t('App bundle ID'),
    [InventoryContentOption.PAGE_URL]: t('Page URL'),
    [InventoryContentOption.PUBLISHER_ID]: t('Publisher ID'),
    [InventoryContentOption.INSTANCE_HASH]: t('Instance Hash'),
    [InventoryContentOption.SITE]: t('Site ID'),
    [InventoryContentOption.KEYWORDS]: t('Keywords'),
    [InventoryContentOption.LINE_ITEM_APP_BUNDLE_ID]: t('App bundle ID'),
  };

  if (!isBefInstance) {
    labels = omit(labels, [InventoryContentOption.PUBLISHER_ID, InventoryContentOption.INSTANCE_HASH]);
    bulkOptions = omit(bulkOptions, [InventoryContentOption.PUBLISHER_ID, InventoryContentOption.INSTANCE_HASH]);
  }

  if (isOa) {
    labels = omit(labels, [InventoryContentOption.KEYWORDS]);
    bulkOptions = omit(bulkOptions, [InventoryContentOption.KEYWORDS]);
  }

  const isSiteTargetingEnabled = parentAccountFeatures['isSiteTargetingEnabled'];
  const isAdUnitTargetingEnabled = parentAccountFeatures['isAdUnitTargetingEnabled'];

  if (customOptions) {
    labels = pick(labels, customOptions.availableOptions);
    bulkOptions = pick(bulkOptions, customOptions.availableOptions);
    !isSiteTargetingEnabled &&
      delete labels[InventoryContentOption.SITE] &&
      delete bulkOptions[InventoryContentOption.SITE];
    !isAdUnitTargetingEnabled &&
      delete labels[InventoryContentOption.ADUNIT] &&
      delete bulkOptions[InventoryContentOption.ADUNIT];
  }

  return (
    <AutoComplete
      textFieldProps={{ label: t('Type') }}
      options={isBulk ? Object.keys(bulkOptions) : Object.keys(labels)}
      renderOptions={{
        dataTest: 'type-option',
      }}
      getOptionLabel={option => labels[option]}
      textFieldReadOnly
      disableClearable
      filterOptions={option => option}
      onChange={(e, value) => onChange(value as InventoryContentOption)}
      value={value}
      data-test="inventory-content-type"
    />
  );
};
