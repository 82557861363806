import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { MouseEvent, ReactNode, SyntheticEvent } from 'react';

import { Button, type ButtonProps } from '../../../Button/Button';
import { DialogTitle } from '../../DialogTitle/DialogTitle';

export interface ConfirmationDialogProps {
  title: string;
  isOpen: boolean;
  isLoading?: boolean;
  onConfirmed: () => void;
  onCancel: () => void;
  onExtraAction?: () => void;
  children: ReactNode;
  confirmLabel?: ReactNode;
  cancelLabel?: ReactNode;
  extraBtnLabel?: ReactNode;
  customContent?: boolean;
  confirmationButtonColor?: ButtonProps['color'];
  confirmationDisabled?: boolean;
  hideConfirm?: boolean;
  showExtraBtn?: boolean;
  extraButtonColor?: ButtonProps['color'];
}

export function ConfirmationDialog(props: ConfirmationDialogProps): JSX.Element {
  const {
    title,
    isOpen,
    onConfirmed,
    onCancel,
    onExtraAction,
    children,
    isLoading,
    confirmLabel = 'Ok',
    cancelLabel = 'Cancel',
    customContent = false,
    confirmationButtonColor = 'primary',
    confirmationDisabled = false,
    hideConfirm = false,
    showExtraBtn = false,
    extraBtnLabel = 'Extra',
    extraButtonColor = 'default',
  } = props;

  const handleOkButton = (event: MouseEvent): void => {
    event.stopPropagation();
    onConfirmed();
  };

  const handleCancelButton = (event: MouseEvent): void => {
    event.stopPropagation();
    onCancel();
  };

  const handleExtraButton = (event: MouseEvent): void => {
    event.stopPropagation();
    if (onExtraAction) onExtraAction();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(event: SyntheticEvent<HTMLElement>, reason) => {
        if (reason === 'escapeKeyDown' || reason === 'backdropClick') {
          event.stopPropagation();
        }
        onCancel();
      }}
      data-test={props['data-test']}
      onClick={e => e.stopPropagation()}
    >
      <DialogTitle dataTest="confirmation-dialog-title" onClose={onCancel} onCloseDisabled={isLoading}>
        {title}
      </DialogTitle>
      {customContent ? (
        children
      ) : (
        <DialogContent data-test="dialog-content" dividers>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions data-test="dialog-actions">
        <Button onClick={handleCancelButton} variant="text" disabled={isLoading} data-test="cancel-button">
          {cancelLabel}
        </Button>

        {showExtraBtn && (
          <Button onClick={handleExtraButton} disabled={isLoading} data-test="extra-button" color={extraButtonColor}>
            {extraBtnLabel}
          </Button>
        )}

        {!hideConfirm && (
          <Button
            onClick={handleOkButton}
            color={confirmationButtonColor}
            disabled={isLoading || confirmationDisabled}
            loading={isLoading}
            autoFocus
            data-test="submit-button"
          >
            {confirmLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
