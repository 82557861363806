import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useMemo, useCallback, type ClipboardEvent, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@openx/components/core/lib/TextField/TextField';
import type { OptionsMap } from '@openx/types';

import { ITEMS_CHARACTER_LIMIT, ITEMS_TEXTAREA_LIMIT } from '../../constants';

import { Items, SelectedItemComponent } from './Items';
import { ShowHideButton } from './ShowHideButton';
import { getDisplayLimit, itemToString } from './utils';

const StyledTextField = styled(TextField, { shouldForwardProp: prop => prop !== 'isReadOnly' })<{
  isReadOnly: boolean;
}>`
  margin-top: ${({ theme, isReadOnly }) => (isReadOnly ? 0 : theme.spacing(1.5))};
  .Mui-disabled {
    background-color: ${({ theme }) => theme.palette.background.paper};
  }
`;

type SelectedItemsProps<ItemType> = {
  ItemComponent?: SelectedItemComponent;
  selectedItems: ItemType[];
  getItemLabel?: (item: ItemType) => string | JSX.Element;
  handleChange?: (values: ItemType[]) => void;
  hideTitle?: boolean;
  filtersTitle?: string;
  readonly?: boolean;
  isAddIcon: boolean;
  dataTest?: string;
  options?: OptionsMap;
  disabledStyle?: boolean;
  isRemovingAll?: boolean;
  customDisplayLimit?: number;
  isDealLibrary?: boolean;
  enableRemoveOneItem?: boolean;
  customLimitMsg?: string;
  showMoreText?: string;
  maxRows?: number;
  opSwitch?: ReactElement;
};

export function SelectedItems<ItemType>({
  ItemComponent,
  selectedItems,
  handleChange = () => undefined,
  hideTitle,
  readonly = true,
  filtersTitle = '',
  dataTest,
  isAddIcon,
  options,
  disabledStyle = false,
  getItemLabel = i => i as unknown as string, // by default component works with strings
  isRemovingAll = false,
  customDisplayLimit,
  isDealLibrary = false,
  customLimitMsg,
  showMoreText = '',
  maxRows,
  enableRemoveOneItem = true,
  opSwitch,
}: SelectedItemsProps<ItemType>): JSX.Element {
  const { t } = useTranslation();
  const [displayLimit, setDisplayLimit] = useState(true);

  const items = selectedItems.map(item => {
    return { item: item, label: getItemLabel(item) };
  });

  const itemsLabels = items.map(i => i.label);
  const itemsDisplayLimit = customDisplayLimit
    ? customDisplayLimit
    : getDisplayLimit(itemsLabels, ITEMS_CHARACTER_LIMIT);

  const onRemove = useCallback(
    (item: string | JSX.Element) => handleChange(items.filter(i => i.label !== item).map(i => i.item)),
    [handleChange, items]
  );

  const displayButton = selectedItems.length > itemsDisplayLimit;
  const notDisplayedCount = selectedItems.length - itemsDisplayLimit;

  const onButtonClick = () => setDisplayLimit(!displayLimit);

  const displayItems = useMemo(() => {
    const showTextarea = selectedItems.length > ITEMS_TEXTAREA_LIMIT;

    if (showTextarea && !displayLimit) {
      const items = itemsLabels.map(item => {
        const stringItem = itemToString(item);
        return (options && options[stringItem]?.name) || stringItem;
      });

      const handleCopy = (event: ClipboardEvent) => {
        event.preventDefault();
        const currentSelection = document.getSelection()?.toString() ?? '';
        const multilineText = currentSelection.replaceAll(' ', '\n');
        navigator.clipboard.writeText(multilineText);
      };

      const msg = readonly
        ? ''
        : customLimitMsg ??
          t('To edit list containing more than {elLimit} items use Bulk Mode.', { elLimit: ITEMS_TEXTAREA_LIMIT });

      return (
        <Grid container>
          <StyledTextField
            autoFocus
            multiline
            fullWidth
            helperText={msg}
            data-test="bulk-textarea"
            value={items.join(', ')}
            disabled={true}
            isReadOnly={readonly}
            InputProps={{ disableUnderline: true }}
            maxRows={maxRows}
            onCopy={handleCopy}
          />
        </Grid>
      );
    }

    return (
      <Items
        onRemove={enableRemoveOneItem ? onRemove : undefined}
        onRemoveAll={() => handleChange([])}
        ItemComponent={ItemComponent}
        selectedItems={displayLimit ? itemsLabels.slice(0, itemsDisplayLimit) : itemsLabels}
        title={filtersTitle}
        hideTitle={hideTitle}
        readonly={readonly}
        data-test={dataTest}
        isAddIcon={isAddIcon}
        options={options}
        disabledStyle={disabledStyle}
        isRemovingAll={isRemovingAll}
        opSwitch={opSwitch}
      />
    );
  }, [
    selectedItems.length,
    displayLimit,
    enableRemoveOneItem,
    onRemove,
    ItemComponent,
    itemsLabels,
    itemsDisplayLimit,
    filtersTitle,
    hideTitle,
    readonly,
    dataTest,
    isAddIcon,
    options,
    disabledStyle,
    isRemovingAll,
    opSwitch,
    customLimitMsg,
    t,
    maxRows,
    handleChange,
  ]);

  const label = !isDealLibrary
    ? t('and {notDisplayedCount} more.', { notDisplayedCount })
    : `and ${notDisplayedCount} more.`;

  const showMoreLabel = showMoreText ? showMoreText : t('show more');

  return (
    <>
      {displayItems}
      {displayButton && (
        <ShowHideButton isDisplayLimit={displayLimit} onClick={onButtonClick} notDisplayedText={label}>
          {displayLimit ? showMoreLabel : t('hide')}
        </ShowHideButton>
      )}
    </>
  );
}
