import { useTargetingContext } from '../../../utils/context/TargetingContext';
import { ContextualSegmentsItems } from '../ContextualSegments/ContextualSegmentsItems';
import { ContextualSegmentsTopBox } from '../ContextualSegments/ContextualSegmentsTopBox';
import { ContextualSegmentsItemsV2 } from '../ContextualSegmentsV2/ContextualSegmentsItemsV2';
import { ContextualSegmentsTopBoxV2 } from '../ContextualSegmentsV2/ContextualSegmentsTopBoxV2';

export const useGetContextualSegmentsComponents = () => {
  const { isOa } = useTargetingContext();

  if (isOa) {
    return {
      ContextualSegmentsItems: ContextualSegmentsItemsV2,
      ContextualSegmentsTopBox: ContextualSegmentsTopBoxV2,
      segmentsV2: !!isOa,
    };
  }

  return {
    ContextualSegmentsItems: ContextualSegmentsItems,
    ContextualSegmentsTopBox: ContextualSegmentsTopBox,
    segmentsV2: !!isOa,
  };
};
