import { Grid, Skeleton } from '@mui/material';

import { PageSection } from '@openx/components/core/lib/PageSection/PageSection';

import {
  StyledButtonSkeleton,
  StyledButtonsContainerSkeleton,
  StyledSkeleton,
  StyledTextSkeletonGrid,
} from './styledComponents';

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <>
      <PageSection divider title="Deal Details">
        <Grid item xs={6}>
          <StyledTextSkeletonGrid container>
            <Grid item xs={6}>
              <StyledSkeleton variant="text" width={100} />
              <StyledSkeleton variant="text" width={85} />
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: '0.8rem' }}>
              <StyledSkeleton variant="text" width={100} />
              <StyledSkeleton variant="text" width={85} />
            </Grid>
          </StyledTextSkeletonGrid>

          <StyledTextSkeletonGrid container>
            <Grid item xs={12}>
              <StyledSkeleton variant="text" width={100} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width={650} sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" width={700} sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" width={600} sx={{ fontSize: '1rem' }} />
            </Grid>
          </StyledTextSkeletonGrid>

          <StyledButtonsContainerSkeleton container>
            <StyledSkeleton variant="text" width={40} sx={{ marginRight: '0.7rem' }} />
            <StyledSkeleton variant="text" width={210} />

            <StyledButtonSkeleton item xs={12}>
              <Skeleton variant="rounded" width={110} height={35} />
            </StyledButtonSkeleton>
          </StyledButtonsContainerSkeleton>
        </Grid>
      </PageSection>

      <PageSection divider title={'Targeting'}>
        <Grid item xs={6}>
          <Skeleton variant="rounded" width={120} height={30} />
        </Grid>
      </PageSection>
    </>
  );
};
