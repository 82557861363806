import type { TFunction } from 'i18next';

import {
  Criteria,
  PackageFields,
  type PackageFormState,
  PrivateMarketFields,
  TargetingFields,
  type TargetingState,
} from '@openx/types';

export { PackageFields, PrivateMarketFields } from '@openx/types';
export type { PackageFormState, TargetingState, PackageFormParams } from '@openx/types';

export const PACKAGE_DESCRIPTION = (t: TFunction): string =>
  t(
    'This section provides information about the package you have selected. Information includes name, description, package dates, and package contact name and email.'
  );

export const MERCHANDISING_DESCRIPTION = (t: TFunction): string =>
  t(
    'This section provides information about the package you have selected, including information about the package logo, domains or app names, and sample URL.'
  );

export const PRIVATE_MARKET_DESCRIPTION = (t: TFunction): string =>
  t(
    'This section enables you to make a package discoverable and provide package rate information for Private Marketplace deals.'
  );

export const TARGETING_DESCRIPTION = (t: TFunction): string =>
  t('The targeting (package filters) may limit the reach of audience.');

export const packageLabels = (t: TFunction) => ({
  [PackageFields.LOCKED_INTERNAL]: t('Lock this package for non-OpenX users.'),
  [PackageFields.NAME]: t('Name'),
  [PackageFields.DESCRIPTION]: t('Description (Optional)'),
  [PackageFields.LOGO]: t('Package Logo (Optional)'),
  [PackageFields.URLS]: t('Sample URLs (Optional)'),
  [PackageFields.DOMAINS]: t('Domains or App names (Optional)'),
  [PackageFields.RATE]: t('Rate Card Price (Optional)'),
  [PrivateMarketFields.DEFAULT_RATE]: t('Rate Card Price (Optional)'),
  [PrivateMarketFields.DISCOUNTED_RATE]: t('Discounted / List Price (Optional)'),
  [PrivateMarketFields.DISCOVERABLE]: t('Discoverable to all buyers'),
  [PackageFields.IS_CURATED]: t('Mark this package as curated.'),
});

export const getInitialTargetingValues = (): TargetingState => ({
  [TargetingFields.EXCLUDE_INDIRECT]: false,
  [TargetingFields.INCLUDE_OPEN_BIDDING_INVENTORY]: false,
  [TargetingFields.DIMENSION]: Criteria.ALL,
  [TargetingFields.INVENTORY_AND_CONTENT]: null,
  [TargetingFields.URL_TARGETING]: null,
  [TargetingFields.CATEGORY]: null,
  [TargetingFields.METACATEGORY]: {
    [TargetingFields.EXCLUDE_MFA]: true,
    excludes: [],
    includes: null,
    inter_dimension_operator: Criteria.ALL,
    keywords: {
      excludes: [],
      includes: [],
    },
  },
  [TargetingFields.GEOGRAPHIC]: null,
  [TargetingFields.TECHNOLOGY_AND_DEVICES]: null,
  [TargetingFields.VIDEO]: null,
  [TargetingFields.VIEW_THROUGH_RATE]: null,
  [TargetingFields.CUSTOM_VARIABLES]: null,
  [TargetingFields.VIEWABILITY]: null,
  [TargetingFields.FORMAT_TYPE]: null,
  [TargetingFields.AUDIENCE]: null,
  [TargetingFields.CONTENT_OBJECT]: null,
});

export const getInitialPckValues = (accountUid = '', pckg?: Partial<PackageFormState>): PackageFormState => {
  const { targeting = {}, ...restValues } = pckg ?? {};

  return {
    [PackageFields.ACCOUNT_UID]: accountUid,
    [PackageFields.LOCKED_INTERNAL]: false,
    [PackageFields.IS_CURATED]: false,
    [PackageFields.NAME]: '',
    [PackageFields.DESCRIPTION]: '',
    [PackageFields.DOMAINS]: '',
    [PackageFields.URLS]: [],
    [PackageFields.LOGO]: '',
    [PackageFields.RATE]: '',
    [PackageFields.PRIVATE_MARKET]: {
      [PrivateMarketFields.DEFAULT_RATE]: '',
      [PrivateMarketFields.DISCOUNTED_RATE]: '',
      [PrivateMarketFields.DISCOVERABLE]: false,
    },
    [PackageFields.TARGETING]: { ...getInitialTargetingValues(), ...targeting },
    ...(restValues ?? {}),
  };
};

// we need to use TargetingFields also because some targeting data exists under separate key
// example: url_targeting is a package key, not package.targeting key
export const packageUsedKeys = [...Object.values(PackageFields), TargetingFields.URL_TARGETING] as string[];
