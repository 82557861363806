import { Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';

import { formatDate, type FormatDateParam } from './utils/formatDate';

type DateLabelProps = FormatDateParam & { displayExactDate?: boolean };

export const DateLabel = ({
  date,
  displayLocalTime = true,
  displayExactDate = false,
  dateFormat = 'yyyy-MM-dd HH:mm:ss',
  showOffset = true,
}: DateLabelProps) => {
  const { dateLabel, fromNow } = useMemo(() => {
    const { fromNow, dateLabel } = formatDate({
      date,
      dateFormat,
      displayLocalTime,
      showOffset,
    });

    return {
      dateLabel,
      fromNow,
    };
  }, [displayLocalTime, date, dateFormat, showOffset]);

  if (displayExactDate) {
    return <Typography sx={{ wordBreak: 'keep-all' }}>{dateLabel}</Typography>;
  }

  return (
    <Tooltip title={dateLabel}>
      <Typography sx={{ wordBreak: 'keep-all' }}>{fromNow}</Typography>
    </Tooltip>
  );
};
