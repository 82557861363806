import { Alert, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core/lib/Loader/Loader';
import { type AdvancedTargetingData, type CustomVariablesData, TargetingFields } from '@openx/types';

import { SelectedItems, FiltersTitle, DimensionChip } from '../../shared';
import type { TargetingItemsProps } from '../../types';
import { useGetContextualData } from '../hooks/useGetContextualData';

import { ProviderDetails } from './components/ProviderDetails';

interface Props extends TargetingItemsProps<AdvancedTargetingData> {
  onAdd?: (ctxSegment: CustomVariablesData) => void;
  type?: string;
  accountUid: string;
  targetingContext?: 'deal' | 'package';
}

export const ContextualSegmentsItemsV2 = ({
  targetingParams,
  onChange = () => {},
  type,
  accountUid,
  readonly,
  targetingContext = 'package',
}: Props) => {
  const { t } = useTranslation();
  const { segments, providerName, loading, providerId } = useGetContextualData(accountUid);
  const { op, contextualV2 } = targetingParams;

  const initProvider = useRef(contextualV2.attr);
  const shouldChangeProvider = initProvider.current !== contextualV2.attr;

  const { includes, excludes } = contextualV2 || { excludes: [], includes: [] };

  const mappedSegmentsMockOptions = useMemo(
    () =>
      segments.reduce((acc, segment) => {
        acc[segment.id] = segment;

        return acc;
      }, {}),
    [segments]
  );

  const handleRemove = (inclusionKey: 'excludes' | 'includes', remainingSegmentIds: string[]) => {
    onChange(prevValues => ({
      ...prevValues,
      contextualV2: {
        ...prevValues.contextualV2,
        [inclusionKey]: remainingSegmentIds,
      },
    }));
  };

  const removeAllSegments = () => {
    onChange(prevValues => ({
      ...prevValues,
      contextualV2: {
        ...prevValues.contextualV2,
        excludes: [],
        includes: [],
      },
    }));
  };

  const segmentsCount = excludes.length + includes.length;
  const hasSegments = segmentsCount > 0;

  if (loading) {
    return <Loader />;
  }

  if (hasSegments) {
    return (
      <Box data-test="sub-section">
        <FiltersTitle
          onClear={removeAllSegments}
          title={t('Contextual Segments [ {count} ]', {
            count: segmentsCount,
          })}
          readonly={readonly}
        />

        <ProviderDetails
          providerFromState={contextualV2.attr}
          component={targetingContext}
          readonly={readonly}
          providerId={providerId}
          providerName={providerName}
          shouldReplaceProvider={shouldChangeProvider}
        />

        {!isEmpty(includes) && (
          <SelectedItems
            dataTest="contains-any-of-segment-ids"
            filtersTitle={t('Contains any of following Segment IDs')}
            selectedItems={includes}
            readonly={readonly}
            getItemLabel={item => mappedSegmentsMockOptions[item]?.name || item}
            isAddIcon
            handleChange={(segmentIds: string[]) => handleRemove('includes', segmentIds)}
          />
        )}

        {!isEmpty(excludes) && !isEmpty(includes) && <DimensionChip targetingDimension={op} sx={{ marginLeft: '0' }} />}

        {!isEmpty(excludes) && (
          <SelectedItems
            dataTest="contains-none-of-segment-ids"
            filtersTitle={t('Contains none of following Segment IDs')}
            selectedItems={excludes}
            getItemLabel={item => mappedSegmentsMockOptions[item]?.name || item}
            readonly={readonly}
            isAddIcon={false}
            handleChange={(segmentIds: string[]) => handleRemove('excludes', segmentIds)}
          />
        )}
      </Box>
    );
  }

  return (
    !providerName &&
    type === TargetingFields.CONTEXTUAL_SEGMENTS && (
      <Alert severity="warning" sx={{ marginTop: 2, maxWidth: '100%' }}>
        {t(
          'Contextual Segments are not available under this account. To add them, choose an account with connected contextual provider in the On Behalf Of section of the {component} form.',
          { component: targetingContext }
        )}
      </Alert>
    )
  );
};
