import { useTranslation } from 'react-i18next';

import type { InvalidItemsError } from '../../shared';
import { useValidateDomain } from '../useValidateDomain';

type ValidationResult = {
  errors: InvalidItemsError[];
  validItems: string[];
};

export const useValidate = () => {
  const { t } = useTranslation();
  const validateDomain = useValidateDomain();

  return async (items: string[]): Promise<ValidationResult> => {
    const added = new Set();

    const duplicates: string[] = [];
    const invalid: string[] = [];
    const validItems: string[] = [];

    items.forEach(domain => {
      if (validateDomain(domain)) {
        invalid.push(domain);
      } else if (added.has(domain)) {
        duplicates.push(domain);
      } else {
        validItems.push(domain);
        added.add(domain);
      }
    });

    const errors: InvalidItemsError[] = [];

    if (duplicates.length) errors.push({ error: t('Duplicates.'), invalidItems: [...new Set(duplicates)] });

    if (invalid.length) errors.push({ error: t('Invalid Domains.'), invalidItems: [...new Set(invalid)] });

    return { errors, validItems };
  };
};
