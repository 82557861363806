import { t } from 'i18next';

import { ComparisonType } from '@openx/types';

import { dropdownOptions } from '../../CustomVariables/constants';

export const getBulkPlaceholder = () =>
  t(`Segment IDs may be entered as either comma, semicolon,
or new line separated values.

Examples:
12345678, 87654321
or 
12345678;87654321
or
12345678
87654321`);

export const filteredTypeOptions = dropdownOptions.filter(
  option => option === ComparisonType.INTERSECTS || option === ComparisonType.NOT_INTERSECTS
);

export const comparisonOptionLabels = () => ({
  [ComparisonType.INTERSECTS]: t('Any of'),
  [ComparisonType.NOT_INTERSECTS]: t('None of'),
});
