import { Grid, Typography } from '@mui/material';

import { Button } from '@openx/components/core/lib/Button/Button';

import { LeafIcon } from 'components/LeafIcon';

import { StyledContainerGrid, StyledGrid } from './styledComponents';

export const GreenDeal = (): JSX.Element => {
  const GREEN_DEAL_URL = 'https://www.openx.com/sustainability/';

  return (
    <StyledContainerGrid container>
      <StyledGrid item xs={10}>
        <LeafIcon />
        <Typography data-test="green-deal-text">This deal is green! Learn more about Sustainability.</Typography>
      </StyledGrid>
      <Grid item xs={'auto'}>
        <Button
          variant="text"
          color="success"
          data-test="read-more-button"
          onClick={() => window.open(GREEN_DEAL_URL, '_blank')}
        >
          Read More
        </Button>
      </Grid>
    </StyledContainerGrid>
  );
};
