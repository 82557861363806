import { Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MetacategoryGroup,
  type MetacategoryGroupType,
  type MetacategoryData,
  type OptionsMap,
  Criteria,
} from '@openx/types';
import { usePermissionContext } from '@openx/utils/permission';

import { useTargetingContext } from '../../../utils';
import { DrawerTopBox, OptionsDropdown } from '../../shared';
import { KeywordsInput } from '../../shared/KeywordsInput';
import { AllowBlockType } from '../../types';

import { METACATEGORY_ITEMS_LIMIT } from './constants';
import { MetacategoryAllowBlockTypeDropdown } from './MetacategoryAllowBlockTypeDropdown';
import { MetacategoryBulkDrawer } from './MetacategoryBulkDrawer';
import { MetacategoryGroupOptionDropdown } from './MetacategoryGroupOptionDropdown';

type TopBoxProps = {
  items: MetacategoryData;
  onChange: (categories: MetacategoryData) => void;
};

export const MetacategoryTopBox = ({ items, onChange }: TopBoxProps) => {
  const { t } = useTranslation();
  const { useMetacategoriesFetch } = useTargetingContext();
  const { isOa } = usePermissionContext();

  const { areMetacategoriesLoading, metacategories, refetchMetacategories } = useMetacategoriesFetch();

  const [categoryType, setCategoryType] = useState<MetacategoryGroupType>(
    MetacategoryGroup.ADDITIONAL_OPENX_CATEGORIES
  );
  const [type, setType] = useState<AllowBlockType>(AllowBlockType.ALLOW);

  const availableOptions: OptionsMap = useMemo(() => {
    return Object.keys(metacategories).reduce((options, key) => {
      const isAllowing = type === AllowBlockType.ALLOW && !items.excludes?.includes(key);
      const isBlocking = type === AllowBlockType.BLOCK && !items.includes?.val.includes(key);

      if (isAllowing || isBlocking) {
        options[key] = metacategories[key];
      }

      return options;
    }, {} as OptionsMap);
  }, [items, metacategories, type]);

  const selectedOptions = useMemo(
    () => (type === AllowBlockType.ALLOW ? new Set(items.includes?.val) : new Set(items.excludes)),
    [items, type]
  );

  useEffect(() => {
    refetchMetacategories();
  }, [refetchMetacategories]);

  const renderedOption = useMemo(() => {
    switch (categoryType) {
      case MetacategoryGroup.ADDITIONAL_OPENX_CATEGORIES:
        return (
          <OptionsDropdown
            loading={areMetacategoriesLoading}
            options={availableOptions}
            onChange={values => {
              onChange({
                ...items,
                exclude_mfa: items.exclude_mfa ?? true,
                excludes: type === AllowBlockType.BLOCK ? Array.from(values) : items.excludes,
                includes:
                  type === AllowBlockType.ALLOW
                    ? values.size
                      ? {
                          op: items.includes?.op || Criteria.ANY,
                          val: type === AllowBlockType.ALLOW ? Array.from(values) : items.includes?.val || [],
                        }
                      : null
                    : items.includes,
              });
            }}
            selectedOptions={selectedOptions}
            placeholder={t('[ Select Additional OpenX Categories Type ]')}
            label={t('Additional OpenX Categories')}
            dataTest="metacategory-input"
          />
        );
      case MetacategoryGroup.KEYWORDS:
        return (
          <KeywordsInput
            keywordsType={type}
            keywords={type === AllowBlockType.ALLOW ? items.keywords.includes : items.keywords.excludes}
            hiddenOptions={type === AllowBlockType.ALLOW ? items.keywords.excludes : items.keywords.includes}
            onChange={values => {
              onChange({
                ...items,
                keywords: {
                  excludes: type === AllowBlockType.BLOCK ? Array.from(values) : items.keywords.excludes,
                  includes: type === AllowBlockType.ALLOW ? Array.from(values) : items.keywords.includes,
                },
              });
            }}
            keywordsLimit={METACATEGORY_ITEMS_LIMIT}
          />
        );
    }
  }, [areMetacategoriesLoading, availableOptions, categoryType, items, onChange, selectedOptions, t, type]);

  return (
    <DrawerTopBox>
      <Grid container spacing={1} alignItems="flex-start">
        {!isOa && (
          <Grid item xs={3}>
            <MetacategoryGroupOptionDropdown value={categoryType} onChange={v => setCategoryType(v)} />
          </Grid>
        )}
        <Grid item xs={3} mt={2}>
          <MetacategoryAllowBlockTypeDropdown allowBlockType={type} setAllowBlockType={setType} />
        </Grid>
        <Grid item xs={isOa ? 9 : 6}>
          {renderedOption}
        </Grid>

        <MetacategoryBulkDrawer
          categoryType={categoryType}
          onChange={onChange}
          items={items}
          allowBlockType={type}
          setAllowBlockType={setType}
        />
      </Grid>
    </DrawerTopBox>
  );
};
