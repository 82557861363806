import { TableCell as MaterialTableCell, SxProps, type Theme } from '@mui/material';
import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, useCallback, useMemo, memo } from 'react';

import { DateLabel } from '@openx/components/core/lib/DateLabel/DateLabel';
import { Ellipsis } from '@openx/components/core/lib/Ellipsis/Ellipsis';
import { HighlightedPhrase } from '@openx/components/core/lib/HighlightedPhrase/HighlightedPhrase';
import { ValuePlaceholder } from '@openx/components/core/lib/ValuePlaceholder/ValuePlaceholder';
import { BaseRow, TableColumn } from '@openx/types';

import { findHighlightPhraseForField, HighlightRules } from '../highlightRules';

import MultiValueTableCell from './MultiValueTableCell';

function LoadingCell(): JSX.Element {
  return (
    <div style={{ width: '75%' }}>
      <Skeleton animation="wave" />
    </div>
  );
}

export interface TableCellProps<RowT extends BaseRow> {
  column: TableColumn<RowT>;
  rowData?: RowT;
  className?: string;
  highlightRules?: HighlightRules;
  overrideTableCellStyles?: SxProps<Theme>;
}

const StyledMaterialTableCell = styled(MaterialTableCell)`
  padding: ${({ theme }) => theme.spacing(0.5, 1.25)};
`;

const TableCell = <RowT extends BaseRow>(props: TableCellProps<RowT>): JSX.Element => {
  const { column, className, rowData, highlightRules, overrideTableCellStyles } = props;

  const highlightPhrase = useMemo(
    () => highlightRules && findHighlightPhraseForField(highlightRules, column.key),
    [highlightRules, column.key]
  );

  const highlighter = useCallback(
    (content: string): ReactNode => {
      if (highlightPhrase) {
        return <HighlightedPhrase searchPhrase={highlightPhrase}>{content}</HighlightedPhrase>;
      }

      return content;
    },
    [highlightPhrase]
  );

  const EllipsisWrapper = useCallback(
    ({ children }) => {
      if (column.ellipsis && column.ellipsisMaxWidth) {
        return <Ellipsis width={column.ellipsisMaxWidth}>{children}</Ellipsis>;
      }

      return children || null;
    },
    [column.ellipsis, column.ellipsisMaxWidth]
  );

  const renderRowData = useCallback(
    (data: RowT): ReactNode => {
      if (column.render) {
        return column.render(data, highlighter);
      }

      if (column.multivalue) {
        return (
          data[column.key]?.length && (
            <MultiValueTableCell
              phrase={highlightPhrase}
              mapBy={column.mapBy}
              values={data[column.key]}
              highlighter={highlighter}
              showTooltip={!!column.multivalueTooltip}
            />
          )
        );
      }

      if (column.isDate) {
        if (!data[column.key]) {
          return <ValuePlaceholder />;
        }

        if (column.isExactDate) {
          return (
            <DateLabel
              date={data[column.key]}
              displayLocalTime={false}
              displayExactDate
              dateFormat="yyyy-MM-dd"
              showOffset={false}
            />
          );
        }

        if (column.isDateTime) {
          return <DateLabel date={data[column.key]} displayExactDate />;
        }

        return <DateLabel date={data[column.key]} />;
      }

      const value = (column.mapBy && column.mapBy[data[column.key]]?.name) || data[column.key];
      return value === false || value ? <EllipsisWrapper>{highlighter(value)}</EllipsisWrapper> : <ValuePlaceholder />;
    },
    [highlighter, column, highlightPhrase, EllipsisWrapper]
  );

  return (
    <StyledMaterialTableCell
      width={column.width}
      align={column.align}
      className={className}
      variant="body"
      data-test={column.dataTest ?? 'table-cell'}
      sx={overrideTableCellStyles}
    >
      {rowData ? renderRowData(rowData) || <ValuePlaceholder /> : <LoadingCell />}
    </StyledMaterialTableCell>
  );
};

export const GenericTableCell = memo(TableCell) as typeof TableCell;
