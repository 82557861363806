import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { type ReactElement, type ReactNode, useMemo } from 'react';

import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';
import { HighlightedPhrase } from '../HighlightedPhrase/HighlightedPhrase';
import { Link, OutsideLink } from '../Link/Link';
import { ValuePlaceholder } from '../ValuePlaceholder/ValuePlaceholder';

const StyledBadgeTypography = styled(Typography)`
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: 10px;
  font-weight: 600;
  margin-top: -3px;
  padding-left: 5px;
  text-transform: uppercase;
  white-space: nowrap;
`;

type NameCellProps = {
  PreTitleIconComponent?: ReactElement;
  badge?: string;
  children?: ReactNode;
  disableBoldId?: boolean;
  disableBoldName?: boolean;
  ellipsis?: boolean;
  id?: number | string;
  isOutsideLink?: boolean;
  name?: string;
  pathTo?: string;
  searchPhrase?: string;
  subRowLabel?: string;
  customIcon?: React.ReactNode;
  copyButtonTooltipText?: string;
};

export const NameCell = ({
  PreTitleIconComponent,
  badge,
  children,
  disableBoldId = false,
  disableBoldName = true,
  ellipsis = false,
  id,
  isOutsideLink = false,
  name,
  pathTo,
  searchPhrase = '',
  subRowLabel = 'ID:',
  customIcon,
  copyButtonTooltipText,
}: NameCellProps) => {
  const CellLink = isOutsideLink ? OutsideLink : Link;
  const idString = id?.toString();

  const NameComponent = useMemo<ReactElement>(() => {
    if (!name) {
      return <ValuePlaceholder />;
    }

    if (pathTo) {
      return (
        <CellLink data-test="row-title" to={pathTo}>
          <HighlightedPhrase searchPhrase={searchPhrase}>{name}</HighlightedPhrase>
        </CellLink>
      );
    }

    return (
      <Typography data-test="row-title" fontWeight={disableBoldName ? 'normal' : 'fontWeightBold'}>
        <HighlightedPhrase searchPhrase={searchPhrase}>{name}</HighlightedPhrase>
      </Typography>
    );
  }, [CellLink, disableBoldName, name, pathTo, searchPhrase]);

  return (
    <Box
      data-test="name-cell-container"
      {...(ellipsis && { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' })}
      sx={{ '&:hover': { '.on-hover-visible': { visibility: 'visible' } } }}
    >
      <Stack direction="row">
        {PreTitleIconComponent && PreTitleIconComponent}
        {NameComponent}
        {!!badge && <StyledBadgeTypography data-test="row-title-badge">{badge}</StyledBadgeTypography>}
      </Stack>

      {idString && (
        <Box data-test="row-subtitle" mt={0.2}>
          <Typography color="textSecondary" display="inline" variant="body2">
            <HighlightedPhrase searchPhrase={searchPhrase}>{subRowLabel}</HighlightedPhrase>
          </Typography>
          <Typography display="inline" fontWeight={disableBoldId ? 'normal' : 'fontWeightBold'} ml={1} variant="body2">
            <HighlightedPhrase searchPhrase={searchPhrase}>{`${idString} `}</HighlightedPhrase>
            {customIcon}
            <Typography className="on-hover-visible" component="span" display="inline" ml={0.5} visibility="hidden">
              <CopyToClipboard tooltipText={copyButtonTooltipText} text={idString} />
            </Typography>
          </Typography>
        </Box>
      )}

      {children}
    </Box>
  );
};
