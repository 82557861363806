import { Box } from '@mui/material';
import { t } from 'i18next';

import { type OptionsMap, AllowBlockType, Criteria, type CriteriaValue } from '@openx/types';

import { SelectedItems, Item, DimensionChip, Switch, type ItemProps } from '../../../../Targeting/shared';

type MetacategoryAllowBlockItemProps = {
  options?: OptionsMap;
  op?: CriteriaValue;
  handleChange: (values: string[], type: AllowBlockType, op?: CriteriaValue) => void;
  includes: string[];
  excludes: string[];
  readonly?: boolean;
  dataTest: string;
};

export const MetacategoryAllowBlockItem = ({
  options,
  op,
  handleChange,
  includes,
  excludes,
  readonly,
  dataTest,
}: MetacategoryAllowBlockItemProps) => {
  const hasAllowedItem = includes?.length !== 0;
  const hasBlockedItem = excludes?.length !== 0;
  const hasBothItems = hasAllowedItem && hasBlockedItem;

  const switchState = op === Criteria.ANY;

  const SwitchComponent = () => (
    <Switch
      switchState={switchState}
      onSwitchChange={() => handleChange(includes, AllowBlockType.ALLOW, switchState ? Criteria.ALL : Criteria.ANY)}
      switchOffLabel={t('all')}
      switchOnLabel={t('any')}
      groupTitle={t('of the following:')}
    />
  );

  const ItemComponent = (props: { item: ItemProps<string | JSX.Element>; op: CriteriaValue }) => (
    <Box
      sx={{
        '&:last-child': {
          '[data-test="dimension-false"]': {
            display: 'none',
          },
        },
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Item {...props.item} />
      <DimensionChip targetingDimension={props.op} sx={{ marginLeft: 0, marginRight: 1 }} />
    </Box>
  );

  return (
    <>
      {hasAllowedItem && (
        <SelectedItems
          filtersTitle={t('Allowed')}
          isRemovingAll
          isAddIcon={true}
          selectedItems={includes}
          readonly={readonly}
          handleChange={values => handleChange(values, AllowBlockType.ALLOW)}
          options={options}
          dataTest={`metacategory-allow-${dataTest}`}
          opSwitch={op && <SwitchComponent />}
          ItemComponent={item => (op ? <ItemComponent item={item} op={op} /> : <Item {...item} />)}
        />
      )}

      {hasBothItems && <DimensionChip targetingDimension={Criteria.ALL} isInsideOption={true} />}

      {hasBlockedItem && (
        <SelectedItems
          filtersTitle={t('Blocked')}
          isRemovingAll
          isAddIcon={false}
          selectedItems={excludes}
          readonly={readonly}
          handleChange={values => handleChange(values, AllowBlockType.BLOCK)}
          options={options}
          dataTest={`metacategory-block-${dataTest}`}
        />
      )}
    </>
  );
};
