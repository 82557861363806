import { Grid, TextField } from '@mui/material';
import { upperFirst } from 'lodash';
import { KeyboardEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { ComparisonType, type UserAgentPayload } from '@openx/types';

import { useComparisonLabels } from '../../constants';
import { comparisonOptions } from '../constants';

type UserAgentDropdownProps = {
  onChange: (options: UserAgentPayload) => void;
  selectedOptions: UserAgentPayload;
};

export const UserAgentDropdown = ({ onChange, selectedOptions }: UserAgentDropdownProps) => {
  const { t } = useTranslation();
  const [comparisonType, setComparisonType] = useState<ComparisonType>(ComparisonType.EQUALS);
  const [errorText, setErrorText] = useState('');
  const labels = useComparisonLabels();

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const targetElement = e.target as HTMLInputElement;
      let { value } = targetElement;

      if (e.key === 'Enter') {
        const selectedComparisonData = selectedOptions.val[comparisonType] || [];
        const optionAlreadyExists = selectedComparisonData.includes(value);
        value = value.trim();

        if (optionAlreadyExists) {
          setErrorText(t('This item is already on the list.'));
          return;
        }

        if (value === '') {
          setErrorText(t('This field cannot be empty.'));
          return;
        }

        onChange({
          op: selectedOptions.op,
          val: { ...selectedOptions.val, [comparisonType]: [...selectedComparisonData, value] },
        });

        setErrorText('');
        targetElement.value = '';
      }
    },
    [comparisonType, onChange, selectedOptions, t]
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <AutoComplete
          options={comparisonOptions}
          renderOptions={{
            dataTest: 'comparsion-option',
          }}
          getOptionLabel={(option: ComparisonType) => upperFirst(labels[option].toLowerCase())}
          textFieldReadOnly
          disableClearable
          onChange={(_, value) => setComparisonType(value)}
          value={comparisonType}
          data-test="user-agent-comparison-options"
        />
      </Grid>

      <Grid item xs={9}>
        <TextField
          name="user-agent-input"
          placeholder={t('Enter an User Agent and press Enter')}
          fullWidth
          margin="dense"
          data-test="user-agent"
          autoComplete="off"
          onKeyUp={handleKeyUp}
          style={{
            margin: 0,
            marginBottom: errorText ? '-20px' : '0px',
          }}
          error={!!errorText}
          helperText={errorText}
        />
      </Grid>
    </Grid>
  );
};
