import { useCallback } from 'react';

import { Button, type ButtonProps } from '@openx/components/core/lib/Button/Button';
import { BaseRow, RowActionButtonBaseProps, RowActionState } from '@openx/types';

function isActionAllowed<RowT extends BaseRow>(rowData: RowT, state?: RowActionState<RowT>): boolean | undefined {
  if (typeof state === 'boolean') {
    return state;
  }

  return state && state(rowData);
}

export interface RowActionButtonProps<RowT extends BaseRow> extends RowActionButtonBaseProps<RowT> {
  rowData: RowT;
  color: NonNullable<ButtonProps['color']>;
}

export function RowActionButton<RowT extends BaseRow>(props: RowActionButtonProps<RowT>): JSX.Element {
  const { color, onClick, rowData, allowed, loading, disabled, notAllowedMessage, href, label, variant } = props;
  const notAllowedMessageString =
    typeof notAllowedMessage === 'function' ? notAllowedMessage(rowData) : notAllowedMessage;

  const labelString = typeof label === 'function' ? label(rowData) : label;
  const onButtonClick = useCallback(e => onClick?.(rowData, e), [onClick, rowData]);

  return (
    <Button
      color={color}
      onClick={onButtonClick}
      allowed={isActionAllowed(rowData, allowed)}
      disabled={isActionAllowed(rowData, disabled)}
      loading={isActionAllowed(rowData, loading)}
      data-test={props['data-test']}
      notAllowedMessage={notAllowedMessageString}
      href={typeof href === 'function' ? href?.(rowData) : href}
      variant={variant}
    >
      {labelString}
    </Button>
  );
}
