import { useMemo } from 'react';

import { AutoCompleteVirtualize } from '@openx/components/core/lib/AutoComplete/AutoCompleteVirtualize';
import type { RenderOptions } from '@openx/components/core/lib/AutoComplete/types';
import { AudienceMap } from '@openx/types';
import { produceSortedOptionsKeys } from '@openx/utils';

interface Props {
  label?: string;
  value: string;
  placeholder: string;
  onChange: (val: string) => void;
  options?: AudienceMap;
  isLoading: boolean;
  renderOptions?: RenderOptions<string>;
}

export const SegmentsDropdown = ({
  value,
  placeholder,
  options,
  isLoading,
  label,
  renderOptions = {},
  onChange,
}: Props) => {
  const optionsList = useMemo(() => produceSortedOptionsKeys(options), [options]);

  return (
    <AutoCompleteVirtualize
      textFieldProps={{ label, placeholder }}
      options={optionsList}
      fullWidth
      loading={isLoading}
      renderOptions={{
        dataTest: 'type-option',
        tooltip: true,
        ...renderOptions,
      }}
      getOptionLabel={option => options?.[option]?.name || option}
      onChange={(e, value) => (value ? onChange(value) : onChange(''))}
      value={isLoading ? '' : value}
      disableClearable
      data-test="audience-segments-input"
    />
  );
};
