import { SxProps } from '@mui/material';
import { useEffect } from 'react';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import type { ComparisonType } from '@openx/types';

type ComparisonTypeLabel = Partial<{
  [key in ComparisonType]: string;
}>;

type ComparisonTypeDropdownProps = {
  onChange: (action: ComparisonType) => void;
  value: ComparisonType | null;
  options: ComparisonType[];
  labels: ComparisonTypeLabel;
  dataTest?: string;
  sx?: SxProps;
};

export const ComparisonTypeDropdown = ({
  dataTest,
  value,
  onChange,
  options,
  labels,
  sx,
}: ComparisonTypeDropdownProps): JSX.Element | null => {
  useEffect(() => {
    if (!value || !options.includes(value)) {
      onChange(options[0]);
    }
  }, [options, value, onChange]);

  if (!value || !options.includes(value)) {
    return null;
  }

  return (
    <AutoComplete
      // Use empty label to align inputs
      options={options}
      renderOptions={{
        dataTest: 'comparsion-option',
      }}
      getOptionLabel={option => labels[option] ?? ''}
      textFieldReadOnly
      disableClearable
      onChange={(e, value) => onChange(value)}
      value={value}
      data-test={dataTest ?? 'comparison-options'}
      sx={sx}
    />
  );
};
