import { Box } from '@mui/material';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core/lib/Loader/Loader';
import {
  AdPlacement,
  Criteria,
  DistributionChannel,
  FormatTypeData,
  Intersect,
  InventoryFormatTypeOption,
} from '@openx/types';

import { useTargetingContext } from '../../utils';
import { SelectedItems, FiltersTitle, EmptyFilterMessage, Switch, DimensionChipLine } from '../shared';

export interface FormatTypeItemsProps {
  targetingParams: FormatTypeData | null;
  readonly?: boolean;
  setDistributionChannel?: Dispatch<SetStateAction<DistributionChannel | null>>;
  setAdPlacement?: Dispatch<SetStateAction<AdPlacement | null>>;
  updateParams?: Dispatch<SetStateAction<FormatTypeData | null>>;
}

export const Items = ({
  targetingParams,
  readonly = true,
  setAdPlacement = () => {},
  setDistributionChannel = () => {},
  updateParams = () => {},
}: FormatTypeItemsProps) => {
  const { t } = useTranslation();
  const { useAdPlacementOptions, useDistributionChannelOptions } = useTargetingContext();
  const { isAdPlacementLoading, adPlacement: adPlacementMap } = useAdPlacementOptions();
  const { isDistributionChannelLoading, distributionChannel: distributionChannelMap } = useDistributionChannelOptions();

  const switchState = targetingParams?.op === Criteria.ANY;
  const distributionChannelSwitchState =
    targetingParams?.[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]?.op === Intersect.NOT_INTERSECTS;
  const adPlacementSwitchState =
    targetingParams?.[InventoryFormatTypeOption.AD_PLACEMENT]?.op === Intersect.NOT_INTERSECTS;

  const distributionChannel = targetingParams?.[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]?.val;
  const adPlacement = targetingParams?.[InventoryFormatTypeOption.AD_PLACEMENT]?.val;

  const handleClearDistributionChannelFilters = useCallback(() => {
    setDistributionChannel(null);
    updateParams({
      ...targetingParams,
      op: switchState ? Criteria.ANY : Criteria.ALL,
      [InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]: null,
    });
  }, [setDistributionChannel, updateParams, targetingParams, switchState]);

  const handleClearAdPlacementFilters = useCallback(() => {
    setAdPlacement(null);
    updateParams({
      ...targetingParams,
      op: switchState ? Criteria.ANY : Criteria.ALL,
      [InventoryFormatTypeOption.AD_PLACEMENT]: null,
    });
  }, [setAdPlacement, updateParams, targetingParams, switchState]);

  const handleDistributionChannel = useCallback(
    updatedValue => {
      const targetingCopy = {
        op: Criteria.ALL,
        ...targetingParams,
        [InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]: updatedValue.length
          ? {
              op: targetingParams?.[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]?.op ?? Intersect.INTERSECTS,
              val: updatedValue?.join(','),
            }
          : null,
      };

      setDistributionChannel(null);
      updateParams(targetingCopy);
    },
    [setDistributionChannel, updateParams, targetingParams]
  );

  const handleAdPlacement = useCallback(
    updatedValue => {
      const targetingCopy = {
        op: Criteria.ALL,
        ...targetingParams,
        [InventoryFormatTypeOption.AD_PLACEMENT]: updatedValue.length
          ? {
              op: targetingParams?.[InventoryFormatTypeOption.AD_PLACEMENT]?.op ?? Intersect.INTERSECTS,
              val: updatedValue?.join(','),
            }
          : null,
      };

      setAdPlacement(null);
      updateParams(targetingCopy);
    },
    [updateParams, setAdPlacement, targetingParams]
  );

  if (isAdPlacementLoading || isDistributionChannelLoading) {
    return <Loader />;
  }

  if (!adPlacement && !distributionChannel) {
    return <EmptyFilterMessage text={t('No filters applied.')} />;
  }

  const showChip = !!(adPlacement?.length && distributionChannel?.length);
  const showSwitch = !!(adPlacement?.length || distributionChannel?.length) && !readonly;

  const distributionChannelFilterTitle = t('Distribution Channel [ {count} ]', {
    count: distributionChannel?.split(',').length,
  });
  const adPlacementFilterTitle = t('Ad Placement [ {count} ]', { count: adPlacement?.split(',').length });

  return (
    <Box
      sx={{
        marginTop: '34px',
      }}
      data-test="sub-section"
    >
      {showSwitch && (
        <Switch
          switchState={switchState}
          onSwitchChange={() =>
            updateParams({
              ...targetingParams,
              op: switchState ? Criteria.ALL : Criteria.ANY,
            })
          }
          switchOffLabel={t('match all')}
          switchOnLabel={t('match any')}
          groupTitle={t('of the following:')}
        />
      )}

      {!!(targetingParams && distributionChannel?.length) && (
        <>
          <FiltersTitle
            onClear={handleClearDistributionChannelFilters}
            title={distributionChannelFilterTitle}
            readonly={readonly}
          />

          {!readonly && (
            <Switch
              disabled={readonly}
              switchState={distributionChannelSwitchState}
              onSwitchChange={() =>
                updateParams({
                  ...targetingParams,
                  ...(targetingParams[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]?.val && {
                    [InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]: {
                      op: distributionChannelSwitchState ? Intersect.INTERSECTS : Intersect.NOT_INTERSECTS,
                      val: targetingParams[InventoryFormatTypeOption.DISTRIBUTION_CHANNEL].val,
                    },
                  }),
                })
              }
              switchOffLabel={t('include')}
              switchOnLabel={t('exclude')}
              groupTitle={t('of the following:')}
            />
          )}

          <SelectedItems
            filtersTitle={distributionChannelSwitchState ? t('excluded') : t('included')}
            isAddIcon={!distributionChannelSwitchState}
            selectedItems={distributionChannel?.replaceAll(' ', '').split(',')}
            readonly={readonly}
            handleChange={handleDistributionChannel}
            options={distributionChannelMap}
            dataTest="distribution-channel"
          />
        </>
      )}

      {showChip && <DimensionChipLine targetingDimension={targetingParams?.op ?? Criteria.ALL} />}

      {!!(targetingParams && adPlacement?.length) && (
        <>
          <FiltersTitle onClear={handleClearAdPlacementFilters} title={adPlacementFilterTitle} readonly={readonly} />

          {!readonly && (
            <Switch
              disabled={readonly}
              switchState={adPlacementSwitchState}
              onSwitchChange={() =>
                updateParams({
                  ...targetingParams,
                  ...(targetingParams[InventoryFormatTypeOption.AD_PLACEMENT]?.val && {
                    [InventoryFormatTypeOption.AD_PLACEMENT]: {
                      op: adPlacementSwitchState ? Intersect.INTERSECTS : Intersect.NOT_INTERSECTS,
                      val: targetingParams[InventoryFormatTypeOption.AD_PLACEMENT].val,
                    },
                  }),
                })
              }
              switchOffLabel={t('include')}
              switchOnLabel={t('exclude')}
              groupTitle={t('of the following:')}
            />
          )}

          <SelectedItems
            filtersTitle={adPlacementSwitchState ? t('excluded') : t('included')}
            isAddIcon={!adPlacementSwitchState}
            selectedItems={adPlacement?.replaceAll(' ', '').split(',')}
            readonly={readonly}
            handleChange={handleAdPlacement}
            options={adPlacementMap}
            dataTest="ad-placement"
          />
        </>
      )}
    </Box>
  );
};
