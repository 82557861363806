import type { Account } from './account';
import type { ValidationError } from './api';
import type { ConstValues, WithRequired } from './helpers';
import type { ListState } from './list';
import type { PacingMode, MonetizingRegion, BudgetType } from './monetizing';
import type { PackageWithTargeting } from './package';
import type { Audience, TargetingState } from './targeting';
import type { i18nTranslate } from './translations';

export const thirdPartyRevenueMethods = {
  CPM: 'CPM',
  PoM: 'PoM',
} as const;
export type ThirdPartyRevenueMethods = ConstValues<typeof thirdPartyRevenueMethods>;
export type ThirdPartyRevenueMethod = typeof thirdPartyRevenueMethods;

export type RboConfig = {
  daily_goal_target?: string | number | null;
  format: string;
  goal?: string | null;
  industry?: string | null;
  initialized_at?: string | null;
  status?: string | null;
  threshold?: string | null;
  tactic?: string | null;
} | null;

export interface DealCreate {
  account_uid: string;
  name?: string | null;
  status: string;
  pmp_deal_type: PmpDealType;
  currency: string;
  deal_price: string;
  // use_market_floor           API Variance
  deal_source: string;
  // deal_participants          API Variance
  package_uid: string;
  deal_supplements: SupplementalAdQuality | null;
  rbo_config?: RboConfig;
  third_party_revenue?: {
    partner: string;
    share: number | null;
    cpm_cap: number | null;
    revenue_method: 'CPM' | 'PoM';
  } | null;
  third_party_fees_config?: null | [ThirdPartyFeeConfig];
  account_executive?: string | null;
  account_manager?: string | null;
  end_date?: string | null;
  start_date?: string;
}

export type ThirdPartyFeeConfig = {
  partner_id: string;
  gross_share?: string | null;
  gross_cpm_cap?: string | null;
  platform_share?: string | null;
  revenue_method: ThirdPartyRevenueMethod['CPM'] | ThirdPartyRevenueMethod['PoM'];
  platform_partner_id?: string;
};

export type ThirdPartyFee = {
  share?: string | null;
  cpm_cap?: string | null;
  fee_type: string;
  partner_id: string;
  revenue_method: 'PoM' | 'CPM';
};

export type ThirdPartyPartnersOption = { id: number; name: string; default_share: string | null };

export interface DealBase extends DealCreate {
  account_uid: string;
  created_date?: string;
  monetizing_uid?: string | null;
  monetizing_id?: string | null;
  is_pacing_enabled: boolean;
  deal_id?: string | null;
  deal_source: string;
  enable_dv360_inventory_package?: boolean;
  expected_ad_category: string | null;
  id: string;
  is_pub_visible: boolean;
  lifetime_impression_goal: string | number | null;
  modified_date?: string;
  name: string;
  open_auction_access: string | null;
  pmp_deal_type: PmpDealType;
  revision?: number;
  start_date?: string;
  third_party_fees?: ThirdPartyFee[] | null;
  type: 'deal';
  type_full: string;
  uid: string;
}

export interface Deal extends DealBase {
  open_auction_access: string;
  use_market_floor: boolean | string | null;
  deal_participants: DealParticipant[];
}

export type EnrichedDeal = DealBase & {
  used_by_postauctiondiscount: boolean;
};

// Deal Form
export interface DealState extends Deal {
  dv360_inventory_package?: DV360InventoryPackage | null;
  synchronized_with?: SynchronizedWith | null;
  generated_deal_id?: string | null;
  custom_deal_id?: string | null;
  generated_deal_id_prefix?: string | null;
  external_deal_id?: string | null;
  deal_id_type_radio?: string | null;
  targeting_section_switch?: TargetingSectionDealFormSwitch;
  monetizing_budget: string;
  monetizing_region: MonetizingRegion | null;
  pacing_mode: PacingMode | null;
  budget_type: BudgetType | null;
  instance_uid: string;
}

export type ListDealBase = Pick<
  Deal,
  | 'id'
  | 'uid'
  | 'name'
  | 'deal_id'
  | 'status'
  | 'start_date'
  | 'end_date'
  | 'modified_date'
  | 'created_date'
  | 'deal_participants'
> & {
  synchronized_with?: SynchronizedWith | null;
};

export type ListDeal = ListDealBase &
  Pick<
    Deal,
    'currency' | 'deal_price' | 'package_uid' | 'pmp_deal_type' | 'monetizing_uid' | 'lifetime_impression_goal'
  >;

export type OAListDeal = ListDealBase & OADealFields;

export interface OADealState extends DealState {
  targeting: TargetingState;
}

export interface DealFormState<T extends Partial<Deal> = DealState> {
  loading: boolean;
  error?: ValidationError<T> | Error;
  currentDeal?: T;
}

type DealApiBase = {
  use_market_floor: '0' | '1' | null;
  deal_participants: DealParticipantApi[];
};

export interface DealApiCreate extends DealCreate, DealApiBase {}

export type DealApiMonetizing = {
  pacing_mode: string;
  budget_type: string;
  region: string;
  budget: string;
  flight_start: string;
  flight_end: string;
};

export interface DealApi
  extends Partial<DealApiMonetizing>,
    DealApiBase,
    WithRequired<
      DealBase,
      | 'modified_date'
      | 'account_executive'
      | 'account_manager'
      | 'revision'
      | 'end_date'
      | 'enable_dv360_inventory_package'
    > {
  instance_uid: string;
  created_date: string;
  notes: string;
  apply_exchange_fee: string | null;
  has_multiple_publishers: string | null;
  price_config: string | null;
  bid_reuse_disabled: number;
  deal_exclusion_rate: string | null;
  account_id: string;
  package_id: string;
  package_name: string;
  package_obj?: PackageWithTargeting;
  v: number;
  deleted: number;
  type: 'deal';
  monetizing_uid: string | null;
  monetizing_id: string | null;
  is_pacing_enabled: boolean;
  expected_ad_category: string | null;
}

export type DealApiSubmit = DealApiCreate & Partial<DealApi>;

// TODO: UIG-540 Fix DealParticipantApi is wrong type and uses of type casting
export interface DealParticipantApi {
  demand_partner: string;
  brand_ids?: Record<string, unknown>;
  buyer_id?: string | null;
  buyer_ids?: Record<string, unknown>;
  main_buyer_id: string | null;
  hasThirdPartyOptions?: boolean;
}

export interface DealParticipant {
  demand_partner: string | null;
  brand_ids: string[];
  buyer_ids: string[];
  main_buyer_id: string | null;
  buyer_id: string | null;
}

interface SupplementalAdQualityFilter {
  op: 'allow' | 'allow_all' | null;
  ids: string[] | Record<string, boolean>;
}

export interface SupplementalAdQuality {
  allow_unbranded_rtb_bids?: '0' | '1';
  lean_filters: {
    disable_total_requests_filter: '0' | '1';
    disable_pixel_buckets: '0' | '1';
  };
  brand_labels: SupplementalAdQualityFilter | null;
  brands: SupplementalAdQualityFilter | null;
  content_attributes: SupplementalAdQualityFilter | null;
  creative_types: SupplementalAdQualityFilter | null;
  languages: SupplementalAdQualityFilter | null;
}

export const pmpDealType = {
  Guaranteed: '4',
  PreferredDeal: '1',
  PrivateAuction: '2',
  WithinOpenAuction: '3',
} as const;
export type PmpDealType = ConstValues<typeof pmpDealType>;

export const targetingSectionDealFormSwitch = {
  CURATED_PACKAGE: 2,
  EXISTING_PACKAGE: 1,
  UNIQUE_TARGETING: 0,
} as const;
export type TargetingSectionDealFormSwitch = ConstValues<typeof targetingSectionDealFormSwitch>;

export const onThelist = {
  Active: 'Active',
  Paused: 'Paused',
} as const;
export type OnThelist = ConstValues<typeof onThelist>;

export interface DV360InventoryPackage {
  description: string;
  on_the_list: OnThelist;
}

export interface SynchronizedWith {
  dv360_inventory?: {
    inventory_package_status: OnThelist;
    inventory_package_name: string;
  } | null;
  dv360seller?: DV360Seller | null;
  ttd_proposal_id?: string | number | null;
}

export type DV360Seller = {
  order_name?: string | number | null;
  reject_reason?: string | null;
  failure_reason?: DV360FailureReason | null;
  reject_date?: string | null;
  failure_date?: string | null;
};

export const DV360FailureReasons = {
  ORDER_CANCELLED: 'order_cancelled',
} as const;
export type DV360FailureReason = ConstValues<typeof DV360FailureReasons>;

export const synchronizationStatuses = {
  FAILED: 2,
  NONE: -1,
  REJECTED: 1,
  SYNCED: 0,
} as const;
export type SynchronizationStatus = ConstValues<typeof synchronizationStatuses>;

export interface MultipartnerDeal extends DealState {
  partner_account_uids: Account[];
  deal_shadow_list: DealShadow[];
}

export interface DealShadow extends Pick<DealState, 'name' | 'deal_price' | 'status' | 'currency'> {
  account_uid: string;
}

export type OADealFields = {
  _package?: {
    audience_id: string | null;
    audience: Audience | null;
  };
  account?: {
    name: string;
  } | null;
  exchange_uid?: string | null;
};

export interface DealOaApi extends DealApi {
  _package?: {
    audience_id?: string | null;
    audience?: Audience | null;
  };
  account?: {
    name: string;
  } | null;
  exchange_uid: string;
}

export interface DealShadowApi extends Partial<Pick<DealApi, 'name' | 'deal_price' | 'account_uid' | 'type_full'>> {
  multipartner_deal_id?: string;
}

export interface MultipartnerDealApi extends DealApi {
  partner_account_uids: string[];
  deal_shadow_list: DealShadowApi[];
}

export const MultipartnerTypeFull = 'deal.multipartner';
export const ShadowDealTypeFull = 'deal.shadow';

export interface DealTroubleshoot {
  timestamp: string;
  stats: {
    bid_requests: number;
    bid_responses: number;
    impressions: number;
  };
}

export const oaDealStatus = {
  ACTIVE: 'Active',
  ERROR: 'Error',
  EXPIRED: 'Expired',
  PAUSED: 'Paused',
  PUBLISHING: 'Publishing',
  READY_FOR_ACTIVATION: 'ReadyForActivation',
} as const;
export type OaDealStatus = ConstValues<typeof oaDealStatus>;

export function getOaDealStatus(t: i18nTranslate) {
  return {
    Active: t('Active'),
    Error: t('Error'),
    Expired: t('Expired'),
    Paused: t('Paused'),
    Publishing: t('Publishing'),
  };
}

export const dealStatus = {
  Active: 'Active',
  Expired: 'Expired',
  GoalReached: 'Goal Reached',
  Paused: 'Paused',
} as const;
export type DealStatus = ConstValues<typeof dealStatus>;

export const dealStatusForm = {
  [dealStatus.Active]: 'active',
  [dealStatus.Expired]: 'inactive',
  [dealStatus.GoalReached]: 'goalreached',
  [dealStatus.Paused]: 'warning',
} as const;

export function getDealStatus(t: i18nTranslate) {
  return {
    Active: t('Active'),
    Error: t('Error'),
    Expired: t('Expired'),
    GoalReached: t('Goal Reached'),
    Paused: t('Paused'),
  };
}

export type DealsListFiltersValue = {
  deal_id?: string;
  exchange_deal_id?: string;
  phrase: string;
  status?: string;
  pmp_deal_type?: string;
  package_uid?: string;
};

export type DealsListState<T extends Partial<Deal> = ListDeal> = ListState<T, DealsListFiltersValue>;

export const dealDetailsTabs = {
  COMMENTS: 'comments',
  DETAILS: 'details',
  HISTORY: 'history',
  TROUBLESHOOT: 'troubleshoot',
} as const;
export type DealDetailsTabs = ConstValues<typeof dealDetailsTabs>;

export enum ThirdPartyRevenueField {
  PARTNER = 'partner',
  SHARE = 'share',
  CPM_CAP = 'cpm_cap',
  REVENUE_METHOD = 'revenue_method',
}

export enum dealFormField {
  ACCOUNT_EXECUTIVE = 'account_executive',
  ACCOUNT_MANAGER = 'account_manager',
  ACCOUNT_UID = 'account_uid',
  BUDGET_TYPE = 'budget_type',
  CURRENCY = 'currency',
  CUSTOM_DEAL_ID = 'custom_deal_id',
  DEAL_ID = 'deal_id',
  DEAL_ID_TYPE_RADIO = 'deal_id_type_radio',
  DEAL_PARTICIPANTS = 'deal_participants',
  DEAL_PRICE = 'deal_price',
  DEAL_SHADOW_LIST = 'deal_shadow_list',
  DEAL_SOURCE = 'deal_source',
  DEAL_SUPPLEMENTS = 'deal_supplements',
  ENABLE_DV360_INVENTORY_PACKAGE = 'enable_dv360_inventory_package',
  END_DATE = 'end_date',
  EXPECTED_AD_CATEGORY = 'expected_ad_category',
  EXTERNAL_DEAL_ID = 'external_deal_id',
  GENERATED_DEAL_ID_PREFIX = 'generated_deal_id_prefix',
  IMPRESSION_GOAL = 'lifetime_impression_goal',
  IS_PACING_ENABLED = 'is_pacing_enabled',
  IS_PUB_VISIBLE = 'is_pub_visible',
  MONETIZING_BUDGET = 'monetizing_budget',
  MONETIZING_REGION = 'monetizing_region',
  NAME = 'name',
  OPEN_AUCTION_ACCESS = 'open_auction_access',
  PACING_MODE = 'pacing_mode',
  PACKAGE_UID = 'package_uid',
  PARTNER_IDS = 'partner_account_uids',
  PMP_DEAL_TYPE = 'pmp_deal_type',
  PREVIEW_DEAL_ID = 'preview_deal_id',
  RBO_CONFIG = 'rbo_config',
  START_DATE = 'start_date',
  STATUS = 'status',
  TARGETING = 'targeting',
  USE_MARKET_FLOOR = 'use_market_floor',
}
export type DealFormField = ConstValues<typeof dealFormField>;

export const rboConfigField = {
  DAILY_GOAL_TARGET: 'daily_goal_target',
  INDUSTRY: 'industry',
  MODEL_GOAL: 'goal',
  MODEL_TYPE: 'format',
  TACTIC: 'tactic',
  THRESHOLD: 'threshold',
} as const;
export type RboConfigField = ConstValues<typeof rboConfigField>;

export interface DealFormData
  extends Partial<
    Omit<
      DealState,
      'account_uid' | 'pmp_deal_type' | 'deal_source' | 'start_date' | 'deal_participants' | 'package_uid' | 'currency'
    >
  > {
  account_uid: string | null;
  pmp_deal_type: PmpDealType | null;
  deal_source: string | null;
  start_date?: string | null;
  deal_participants: DealParticipant[];
  package_uid: string | null;
  currency: string | null;
  deal_id_type?: string | null;
  custom_deal_id?: string | null;
  external_deal_id?: string | null;
  generated_deal_id_prefix?: string | null;
}

export interface DealFormDataWithTargeting extends DealFormData {
  targeting: TargetingState;
}

export type DealsTableFetchOptions = {
  selectedAccountId?: string;
};
