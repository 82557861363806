import { useReducer } from 'react';

import { type State } from './bulk';
import { reducer } from './bulkReducer';

export const useBulk = (initItems: string) => {
  const initialState: State = {
    errors: [],
    isResetEnabled: true,
    isValidating: false,
    validItems: [],
    value: initItems,
  };

  const [bulk, dispatchBulkChange] = useReducer(reducer, initialState);

  return { bulk, dispatchBulkChange };
};
