import type { ReactNode } from 'react';

import { Loader, LoaderProps } from './Loader';

export type WhenLoadedProps = {
  children: ReactNode;
  loaderClassName?: string;
  loading: boolean;
} & LoaderProps;

export const WhenLoaded = ({ children, loaderClassName, loading, ...rest }: WhenLoadedProps) => {
  if (loading) {
    return <Loader className={loaderClassName} {...rest} />;
  }

  return children;
};
