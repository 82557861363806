import { CriteriaValue } from './targetingValuesTypes';

export type MetacategoryAPI = {
  exclude_mfa: boolean;
  includes?: { op: CriteriaValue; val: string[] } | null;
  excludes?: string[];
  inter_dimension_operator?: CriteriaValue;
  keywords?: {
    includes?: string[];
    excludes?: string[];
  };
};

export type MetacategoryData = {
  exclude_mfa: boolean;
  includes: { op: CriteriaValue; val: string[] } | null;
  excludes: string[];
  inter_dimension_operator: CriteriaValue;
  keywords: {
    includes: string[];
    excludes: string[];
  };
};

export type MetacategoryOption = {
  metacategory_code: string;
  id: string;
  name: string;
  object: 'metacategory';
};

export const MetacategoryGroup = {
  ADDITIONAL_OPENX_CATEGORIES: 'additional_openx_categories',
  KEYWORDS: 'keywords',
} as const;

export type MetacategoryGroupType = (typeof MetacategoryGroup)[keyof typeof MetacategoryGroup];

export type MetacategoryOptionsMap = Record<string, MetacategoryOption>;
