import { InfoRounded } from '@mui/icons-material';
import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { css, styled } from '@mui/material/styles';
import i18n from 'i18next';
import { useCallback } from 'react';

import { Checkbox } from '../Checkbox/Checkbox';
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';
import { DateLabel } from '../DateLabel/DateLabel';
import { Loader } from '../Loader/Loader';

interface FieldDetailsProps {
  title: string;
  value?: string[] | string | boolean | null | number;
  customEmptyValue?: string;
  checkbox?: boolean;
  loading?: boolean;
  copy?: boolean;
  toggle?: boolean;
  dateFormat?: string;
  onOffControl?: boolean;
  textarea?: boolean;
  fullWidth?: boolean;
  customStyles?: {
    box?: { spacingBottom?: number; paddingLeft?: string; borderLeft?: string };
    onOffControlTitle?: { spacingTop?: number; spacingBottom?: number };
  };
  tooltipIconText?: string;
  hideTitle?: boolean;
  dataTest?: string;
  copyTooltipText?: string;
}

const sharedBoxStyles = (theme: Theme, customStyles: FieldDetailsProps['customStyles']) => css`
  margin-bottom: ${theme.spacing(customStyles?.box?.spacingBottom ?? 2)};

  border-left: ${customStyles?.box?.borderLeft ?? 'none'};
  padding-left: ${customStyles?.box?.paddingLeft ?? 0};

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledBox = styled(Box, { shouldForwardProp: prop => prop !== 'customStyles' })<{
  customStyles: FieldDetailsProps['customStyles'];
}>`
  ${({ theme, customStyles }) => sharedBoxStyles(theme, customStyles)}
`;

const StyledFormControl = styled(FormControl, { shouldForwardProp: prop => prop !== 'customStyles' })<{
  customStyles: FieldDetailsProps['customStyles'];
}>`
  ${({ theme, customStyles }) => sharedBoxStyles(theme, customStyles)}
`;

const StyledTitleTypography = props => (
  <Typography {...props} sx={{ color: 'text.secondary', lineHeight: '16px', mb: 0.75 }} />
);

const StyledEmptyValueTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'isEmpty',
})<{ isEmpty: boolean }>`
  ${({ isEmpty }) =>
    isEmpty &&
    css`
      color: #b3b3b3;
    `}
`;

const StyledSpan = styled('span')`
  display: block;
  word-break: break-word;
`;

const StyledCopyToClipboard = styled(CopyToClipboard)`
  vertical-align: middle;
`;

const StyledTextField = styled(TextField)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const StyledOnOffTitleTypography = styled(Typography, { shouldForwardProp: prop => prop !== 'customStyles' })<{
  customStyles: FieldDetailsProps['customStyles'];
}>`
  margin-bottom: ${({ theme, customStyles }) => theme.spacing(customStyles?.onOffControlTitle?.spacingBottom ?? 1)};
  margin-top: ${({ theme, customStyles }) => theme.spacing(customStyles?.onOffControlTitle?.spacingTop ?? 3)};
`;

const StyledOnOffControlSpan = styled('span')<{ isOff: boolean }>`
  color: ${({ theme }) => theme.palette.success.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  white-space: nowrap;

  ${({ theme, isOff }) =>
    isOff &&
    css`
      color: ${theme.palette.error.main};
    `};
`;

export function FieldDetails({
  title,
  value,
  checkbox,
  loading,
  copy,
  toggle,
  dateFormat,
  onOffControl,
  customStyles,
  customEmptyValue,
  textarea,
  tooltipIconText,
  fullWidth = true,
  hideTitle = false,
  dataTest,
  copyTooltipText,
}: FieldDetailsProps) {
  const isEmpty = !(Array.isArray(value) ? value.length : value);

  const renderValue = useCallback(
    (loading, copy, value, title, dateFormat) => {
      const renderList = Array.isArray(value) ? value : [value];
      if (loading) return <Loader size={20} noPadding />;
      if (!isEmpty && (copy || tooltipIconText)) {
        return (
          <StyledEmptyValueTypography
            data-test={dataTest ?? `${title.toLowerCase().replace(/\s/g, '-')}-value`}
            variant="body1"
            isEmpty={isEmpty}
          >
            {renderList.map((el, idx) => (
              <StyledSpan key={idx}>
                {el}
                {copy && (
                  <StyledCopyToClipboard tooltipText={copyTooltipText} text={value as string} iconSize="medium" />
                )}
                {tooltipIconText && (
                  <Tooltip placement="top" title={tooltipIconText}>
                    <IconButton data-test="tooltip-icon">
                      <InfoRounded />
                    </IconButton>
                  </Tooltip>
                )}
              </StyledSpan>
            ))}
          </StyledEmptyValueTypography>
        );
      }

      return (
        <StyledEmptyValueTypography
          data-test={dataTest ?? `${title.toLowerCase().replace(/\s/g, '-')}-value`}
          variant="body1"
          isEmpty={isEmpty}
        >
          {!isEmpty
            ? renderList.map((el, idx) => (
                <StyledSpan key={idx}>
                  {dateFormat ? (
                    <DateLabel date={el} displayLocalTime={false} displayExactDate showOffset dateFormat={dateFormat} />
                  ) : (
                    el
                  )}
                </StyledSpan>
              ))
            : customEmptyValue ?? i18n.t('N / A')}
        </StyledEmptyValueTypography>
      );
    },
    [isEmpty, tooltipIconText, dataTest, customEmptyValue, copyTooltipText]
  );

  if (checkbox) {
    return (
      <FormControl data-test={dataTest ?? `${title.toLowerCase().replace(/\s/g, '-')}`} fullWidth={fullWidth}>
        <FormControlLabel control={<Checkbox checked={!isEmpty} disabled />} label={title} />
      </FormControl>
    );
  }

  if (textarea) {
    return (
      <StyledBox customStyles={customStyles} data-test={title.toLowerCase().replace(/\s/g, '-')}>
        <StyledTitleTypography
          data-test={`${title.toLowerCase().replace(/\s/g, '-')}-title`}
          variant="body2"
          color="textSecondary"
        >
          {title}
        </StyledTitleTypography>
        {isEmpty ? (
          <StyledEmptyValueTypography
            data-test={`${title.toLowerCase().replace(/\s/g, '-')}-value`}
            variant="body1"
            isEmpty={isEmpty}
          >
            {i18n.t('N / A')}
          </StyledEmptyValueTypography>
        ) : (
          <StyledTextField multiline rows={4} fullWidth={fullWidth} value={value} InputProps={{ readOnly: true }} />
        )}
      </StyledBox>
    );
  }

  if (toggle) {
    const on = value === '1' || value === true;
    const valueText = on ? 'On' : 'Off';

    return (
      <StyledFormControl
        customStyles={customStyles}
        data-test={dataTest ?? `${title.toLowerCase().replace(/\s/g, '-')}`}
        fullWidth={fullWidth}
      >
        <FormControlLabel
          control={
            <>
              <Switch checked={value === '1' || value === true} disabled />
              <StyledOnOffControlSpan data-test="switch-value" isOff={!on}>
                {valueText}
              </StyledOnOffControlSpan>
            </>
          }
          label={title}
        />
      </StyledFormControl>
    );
  }

  if (onOffControl) {
    const on = value === '1' || value === true;
    const valueText = on ? 'On' : 'Off';

    return (
      <StyledBox customStyles={customStyles} data-test={title.toLowerCase().replace(/\s/g, '-')}>
        <StyledOnOffTitleTypography
          customStyles={customStyles}
          data-test={dataTest ?? `${title.toLowerCase().replace(/\s/g, '-')}-title`}
          variant="body1"
        >
          <StyledOnOffControlSpan isOff={!on}>{valueText}</StyledOnOffControlSpan>
          {title}
        </StyledOnOffTitleTypography>
      </StyledBox>
    );
  }

  return (
    <StyledBox
      customStyles={customStyles}
      data-test={title.includes('(') ? 'item-title' : `${title.toLowerCase().replace(/\s/g, '-')}`}
    >
      {!hideTitle && (
        <StyledTitleTypography
          data-test={dataTest ?? `${title.toLowerCase().replace(/\s/g, '-')}-title`}
          variant="body2"
          color="textSecondary"
        >
          {title}
        </StyledTitleTypography>
      )}
      {renderValue(loading, copy, value, title, dateFormat)}
    </StyledBox>
  );
}
