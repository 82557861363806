import { Typography } from '@mui/material';
import { ReactNode } from 'react';

import { Button } from '@openx/components/core/lib/Button/Button';

type ShowHideButtonProps = {
  isDisplayLimit: boolean;
  onClick: () => void;
  notDisplayedText: string;
  children: ReactNode;
};

export const ShowHideButton = ({
  children,
  onClick,
  isDisplayLimit,
  notDisplayedText,
}: ShowHideButtonProps): JSX.Element => {
  return (
    <>
      {isDisplayLimit && (
        <Typography data-test="items-over-limit-info" variant="body2" color="textSecondary">
          {notDisplayedText}
        </Typography>
      )}
      <Button data-test="show-hide-button" variant="text" color="primary" onClick={onClick}>
        {children}
      </Button>
    </>
  );
};
