import { Divider, Grid, GridSize, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

import { StatusIndicator } from '../StatusIndicator/StatusIndicator';

const StyledWrapperDiv = styled('div')`
  width: 100%;
`;

const StyledContainerGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(4)};
`;

const StyledStatusIndicator = styled(StatusIndicator)`
  display: block;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

type PageSectionProps = {
  children?: ReactNode;
  divider?: boolean;
  title: string;
  titleSize?: GridSize;
  description?: string | ReactNode;
} & StatusProps;

type StatusProps =
  | {
      status?: undefined;
    }
  | {
      status: string;
      statusLabel?: string;
    };

export function PageSection(props: PageSectionProps): JSX.Element {
  const { children, divider, title, status, description, titleSize = 3 } = props;

  return (
    <StyledWrapperDiv data-test={title.toLowerCase().replace(/\s/g, '-')}>
      <StyledContainerGrid container>
        <Grid item xs={titleSize}>
          <Typography
            data-test="title"
            sx={{
              ':before': {
                borderTopColor: 'primary.main',
                borderTopStyle: 'solid',
                borderTopWidth: '1px ',
                content: '""',
                display: 'block',
                marginBottom: '8px',
                width: '12px',
              },
            }}
            variant="h2"
          >
            {title}
          </Typography>

          {status && <StyledStatusIndicator status={status} label={props.statusLabel} data-test="status" />}

          {description}
        </Grid>
        {children}
      </StyledContainerGrid>
      {divider && <Divider />}
    </StyledWrapperDiv>
  );
}
