import { t } from 'i18next';

import { ComparisonType, type ContextualSegmentsV2FormState, type SegmentsOptions } from '@openx/types';

import type { InvalidItemsError } from '../../shared';

export const validateBulkContextualSegments = async (
  addedItems: string[],
  contextualItems: ContextualSegmentsV2FormState
) => {
  const itemsOnList = new Set(contextualItems?.excludes.concat(contextualItems?.includes) ?? []);
  const duplicates: string[] = [];
  const validItems: string[] = [];
  const errors: InvalidItemsError[] = [];

  addedItems.forEach(item => {
    if (itemsOnList.has(item)) {
      duplicates.push(item);
    } else {
      validItems.push(item);
    }
  });

  const uniqueDuplicates = [...new Set(duplicates)];
  const uniqueValidItems = [...new Set(validItems)];

  if (uniqueDuplicates.length) errors.push({ error: t('Duplicates.'), invalidItems: uniqueDuplicates });

  return { errors, validItems: uniqueValidItems };
};

export const filterSegmentsOptions = (
  contextualV2: ContextualSegmentsV2FormState = {
    attr: '',
    excludes: [],
    includes: [],
    op: ComparisonType.INTERSECTS,
  },
  segments: SegmentsOptions,
  searchInput: string
) => {
  const { includes, excludes } = contextualV2;

  const includesSet = new Set(includes);
  const excludesSet = new Set(excludes);

  const filterSelected = segments.filter(option => !includesSet.has(option.id) && !excludesSet.has(option.id));

  if (!searchInput) return filterSelected;

  const filterByPhrase = filterSelected.filter(option => option.name.toLowerCase().includes(searchInput.toLowerCase()));

  return filterByPhrase;
};
