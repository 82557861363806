import { Alert, Box, Grid } from '@mui/material';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdvancedTargetingData,
  ComparisonType,
  ContextualSegmentsDataPayload,
  Criteria,
  CriteriaValue,
  CustomVariablesData,
  CustomVariablesDataPayload,
} from '@openx/types';

import { convertContextualToCustom, prepareContextualSegments } from '../../ContextualSegments/helpers';
import { SelectedItems, FiltersTitle, DimensionChip, Switch } from '../../shared';
import { TargetingItemsProps } from '../../types';

import { SegmentIdsItems } from './SegmentIdsItems';

const StyledInfoAlert = props => <Alert severity="info" sx={{ marginTop: 3, maxWidth: 'max-content' }} {...props} />;

interface Props extends TargetingItemsProps<AdvancedTargetingData> {
  onAdd?: (ctxSegment: CustomVariablesData) => void;
}

export const ContextualSegmentsItems = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
  onAdd = () => {},
}: Props) => {
  const { t } = useTranslation();
  const ctxSegments = useMemo(
    () =>
      prepareContextualSegments(
        structuredClone({
          op: targetingParams.op,
          val: targetingParams.contextual,
        })
      ).val,
    [targetingParams.op, targetingParams.contextual]
  );
  const op = targetingParams.op;
  const switchState = op === Criteria.ANY;

  const handleChange = useCallback(
    (values: ContextualSegmentsDataPayload[]) => {
      const ctxVal: CustomVariablesDataPayload[] = convertContextualToCustom({ op, val: values }).val;

      onChange({
        contextual: ctxVal,
        contextualV2: null as any,
        custom: [],
        op,
      });
    },
    [onChange, op]
  );

  const upsertContextualSegment = useCallback(
    (contextualSegment: ContextualSegmentsDataPayload) => {
      const variableIndex = ctxSegments.findIndex(ctxSegment => ctxSegment.attr === contextualSegment.attr);

      if (variableIndex !== -1) {
        ctxSegments.splice(variableIndex, 1);
      }

      const values = convertContextualToCustom({ op, val: [...ctxSegments, contextualSegment] }).val;

      onAdd({
        op,
        val: values.length ? values : [{ attr: contextualSegment.attr, op: ComparisonType.INTERSECTS, val: '' }],
      });
    },
    [op, onAdd, ctxSegments]
  );

  const handleRemove = useCallback(
    (item: ContextualSegmentsDataPayload) => {
      handleChange(
        ctxSegments.reduce((acc, variable) => {
          if (variable.attr !== item.attr) {
            acc.push({ ...variable, attr: variable.attr });
          }

          return acc;
        }, [] as ContextualSegmentsDataPayload[])
      );
    },
    [ctxSegments, handleChange]
  );

  const ContextualSegmentsList = useMemo(() => {
    return ctxSegments.length ? (
      <Grid data-test={'section-items-contextual-segments'}>
        {ctxSegments.map((item, index) => (
          <Fragment key={item.attr}>
            <SelectedItems
              filtersTitle={t('Provider name')}
              hideTitle={index !== 0}
              isAddIcon={true}
              selectedItems={[item]}
              readonly={readonly}
              handleChange={() => handleRemove(item)}
              getItemLabel={item => item.attr}
              dataTest="contextual-segments-provider"
            />
            <Box marginLeft={3.75}>
              <SegmentIdsItems
                readonly={readonly}
                item={item}
                targetingDimension={op as CriteriaValue}
                operatorSwitch={
                  <Box marginTop={2}>
                    <Switch
                      switchState={switchState}
                      onSwitchChange={() => {
                        onChange({
                          contextual: convertContextualToCustom({ op, val: ctxSegments }).val,
                          contextualV2: null as any,
                          custom: [],
                          op: op === Criteria.ALL ? Criteria.ANY : Criteria.ALL,
                        });
                      }}
                      switchOffLabel={t('all')}
                      switchOnLabel={t('any')}
                      groupTitle={t('of the following:')}
                    />
                  </Box>
                }
                onChange={upsertContextualSegment}
              />
              {index < ctxSegments.length - 1 && (
                <DimensionChip isInsideOption={true} targetingDimension={op as CriteriaValue} />
              )}
            </Box>
          </Fragment>
        ))}
      </Grid>
    ) : null;
  }, [t, onChange, handleRemove, readonly, ctxSegments, op, switchState, upsertContextualSegment]);

  const segmentsCount = ctxSegments.reduce((acc, item) => {
    if (item?.includes?.length) {
      return acc + item.includes.split(',').length;
    }

    return acc;
  }, 0);
  return ctxSegments?.length ? (
    <Box data-test="sub-section">
      <FiltersTitle
        onClear={() => handleChange([])}
        title={t('Contextual Segments [ {count} ]', {
          count: segmentsCount,
        })}
        readonly={readonly}
      />
      {ContextualSegmentsList}
    </Box>
  ) : (
    <Grid data-test="empty-section">
      <StyledInfoAlert>{t('You will be able to add the segments IDs after adding provider name.')}</StyledInfoAlert>
    </Grid>
  );
};
