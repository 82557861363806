import { useTranslation } from 'react-i18next';

import { AutoComplete } from '@openx/components/core/lib/AutoComplete/AutoComplete';
import { InventoryFormatTypeOption } from '@openx/types';

type InventoryContentOptionDropdownProps = {
  onChange: (type: InventoryFormatTypeOption) => void;
  value: InventoryFormatTypeOption;
};

export const FormatTypeGroupOptionDropdown = (props: InventoryContentOptionDropdownProps) => {
  const { onChange, value } = props;
  const { t } = useTranslation();

  const labels: Record<InventoryFormatTypeOption, string> = {
    [InventoryFormatTypeOption.DISTRIBUTION_CHANNEL]: t('Distribution Channel'),
    [InventoryFormatTypeOption.AD_PLACEMENT]: t('Ad Placement'),
  };

  return (
    <AutoComplete
      textFieldProps={{ label: t('Type') }}
      options={Object.keys(labels)}
      renderOptions={{
        dataTest: 'type-option',
      }}
      getOptionLabel={option => labels[option]}
      textFieldReadOnly
      disableClearable
      onChange={(_, value) => onChange(value as InventoryFormatTypeOption)}
      value={value}
      sx={{
        marginTop: '4px',
      }}
      data-test="format-type-category-type"
    />
  );
};
