import {
  ComparisonType,
  contextualSegmentPrefix,
  type ContextualSegmentsData,
  type CustomVariablesData,
  type CustomVariablesDataPayload,
} from '@openx/types';

export const removeNotAllowedSymbols = (text: string): string => {
  const notAllowedInputSymbols = /[^a-zA-Z0-9\s-_]/g;

  return text.trim().replace(notAllowedInputSymbols, '');
};

export const prepareContextualSegments = (contextualSegments: CustomVariablesData): ContextualSegmentsData => {
  let ctxSegments = [];

  if (contextualSegments.val?.length) {
    const groupedData = contextualSegments.val.reduce((acc, { attr, op, val }) => {
      if (!acc[attr]) {
        acc[attr] = {
          attr,
          excludes: '',
          includes: '',
        };
      }

      if (op === ComparisonType.INTERSECTS) {
        acc[attr].includes = val;
      } else if (op === ComparisonType.NOT_INTERSECTS) {
        acc[attr].excludes = val;
      }

      return acc;
    }, {});

    ctxSegments = Object.values(groupedData);
  }

  return { ...contextualSegments, val: ctxSegments };
};

export const convertContextualToCustom = (contextualSegments: ContextualSegmentsData): CustomVariablesData => {
  const custom: CustomVariablesData = { op: contextualSegments.op, val: [] };

  contextualSegments.val.forEach(ctxSegment => {
    if (ctxSegment.includes.length) {
      custom.val.push({ attr: ctxSegment.attr, op: ComparisonType.INTERSECTS, val: ctxSegment.includes });
    }

    if (ctxSegment.excludes.length) {
      custom.val.push({ attr: ctxSegment.attr, op: ComparisonType.NOT_INTERSECTS, val: ctxSegment.excludes });
    }

    if (!ctxSegment.includes.length && !ctxSegment.excludes.length) {
      custom.val.push({ attr: ctxSegment.attr, op: ComparisonType.INTERSECTS, val: '' });
    }
  });

  return custom;
};

export const getContextualSegments = (contextualSegments?: CustomVariablesData | null): CustomVariablesData | null => {
  if (!contextualSegments?.val || !Array.isArray(contextualSegments?.val)) return null;

  const ctxSegments = contextualSegments.val.reduce((acc, ctxSegment) => {
    if (ctxSegment.attr.startsWith(contextualSegmentPrefix)) {
      acc.push({ ...ctxSegment, attr: ctxSegment.attr.slice(contextualSegmentPrefix.length) });
    }

    return acc;
  }, [] as CustomVariablesDataPayload[]);

  return {
    ...contextualSegments,
    val: ctxSegments,
  };
};
