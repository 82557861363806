import { Alert, Box } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core/lib/Loader/Loader';
import type { ContentObjectOption, UseContentObjectOptionsConfig } from '@openx/types';

import { useTargetingContext } from '../../../../utils';
import { SelectedItems, FiltersTitle, DimensionChip } from '../../../shared';
import { AllowBlockType } from '../../../types';
import { itemTitle } from '../../constants';
import type { ContentObjectItems } from '../../types';

export const HierarchyItems = (
  props: ContentObjectItems & { type: Extract<ContentObjectOption, 'genre' | 'contentrating'> }
): JSX.Element => {
  const { useContentObjectOptionsConfig } = useTargetingContext();

  if (!useContentObjectOptionsConfig) {
    throw new Error('useContentObjectOptionsConfig is not provided');
  }

  return <HierarchyItemsRenderer {...props} useContentObjectOptionsConfig={useContentObjectOptionsConfig} />;
};

const HierarchyItemsRenderer = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
  type,
  customItemsSorter,
  useContentObjectOptionsConfig,
}: ContentObjectItems & {
  useContentObjectOptionsConfig: UseContentObjectOptionsConfig;
  type: Extract<ContentObjectOption, 'genre' | 'contentrating'>;
}) => {
  const { t } = useTranslation();

  const { useOptionFetch } = useContentObjectOptionsConfig();

  const { loading: isLoading, options } = useOptionFetch(type);
  const { allow, block } = targetingParams[type];

  const hasAllowed = allow?.length !== 0;
  const hasBlocked = block?.length !== 0;

  const allowedItems = customItemsSorter ? customItemsSorter(allow) : allow;
  const blockedItems = customItemsSorter ? customItemsSorter(block) : block;

  const itemsSet = useMemo(
    () => new Set([...(allowedItems || []), ...(blockedItems || [])]),
    [allowedItems, blockedItems]
  );

  const handleChange = useCallback(
    (values: string[], valueType: AllowBlockType) => {
      if (valueType === AllowBlockType.ALLOW) {
        onChange(prevState => ({ ...prevState, [type]: { allow: values, block: blockedItems } }));
        return;
      }

      onChange(prevState => ({ ...prevState, [type]: { allow: allowedItems, block: values } }));
    },
    [allowedItems, blockedItems, onChange, type]
  );

  const handleOnClear = useCallback(() => {
    onChange(prevState => ({ ...prevState, [type]: { allow: [], apiAllow: [], apiBlock: [], block: [] } }));
  }, [onChange, type]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box data-test="sub-section">
      <FiltersTitle onClear={handleOnClear} title={itemTitle(type, itemsSet.size)} readonly={readonly} />

      {(hasAllowed || hasBlocked) && !readonly && (
        <Alert
          data-test={`${type}-hierarchy-alert`}
          sx={{
            display: 'inline-flex',
            marginBottom: 1,
            width: 'auto !important',
          }}
          severity="info"
        >
          {t('Please adjust targeted values using the hierarchy drop-down only.')}
        </Alert>
      )}

      {hasAllowed && (
        <SelectedItems
          filtersTitle={t('Allowed')}
          enableRemoveOneItem={false}
          isAddIcon={true}
          selectedItems={allowedItems ?? []}
          readonly={readonly}
          handleChange={values => handleChange(values, AllowBlockType.ALLOW)}
          options={options}
          dataTest={`${type}-allow`}
        />
      )}

      {hasAllowed && hasBlocked && <DimensionChip targetingDimension={'AND'} isInsideOption={true} />}

      {hasBlocked && (
        <SelectedItems
          filtersTitle={t('Blocked')}
          enableRemoveOneItem={false}
          isAddIcon={false}
          selectedItems={blockedItems ?? []}
          readonly={readonly}
          handleChange={values => handleChange(values, AllowBlockType.BLOCK)}
          options={options}
          dataTest={`${type}-block`}
        />
      )}
    </Box>
  );
};
