import { Box } from '@mui/system';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from '@openx/components/core/lib/Loader/Loader';
import {
  Criteria,
  type CriteriaValue,
  MetacategoryGroup,
  type MetacategoryGroupType,
  type MetacategoryData,
} from '@openx/types';

import { useTargetingContext } from '../../../../utils';
import { FiltersTitle, EmptyFilterMessage, Switch, DimensionChipLine } from '../../../shared';
import { type TargetingItemsProps, AllowBlockType } from '../../../types';

import { MetacategoryAllowBlockItem } from './MetacategoryAllowBlockItem';

export const MetacategoryItems = ({
  targetingParams,
  readonly = true,
  onChange = () => {},
}: TargetingItemsProps<MetacategoryData>) => {
  const { t } = useTranslation();
  const { useMetacategoriesFetch } = useTargetingContext();

  const { refetchMetacategories, metacategories, areMetacategoriesLoading } = useMetacategoriesFetch();
  const clonedTargetingParams = useMemo(() => cloneDeep(targetingParams), [targetingParams]);
  const { includes, excludes, inter_dimension_operator, keywords } = clonedTargetingParams;

  const keywordsIncludes = keywords.includes;
  const keywordsExcludes = keywords.excludes;

  const itemsSet = useMemo(() => new Set([...(includes?.val || []), ...excludes]), [includes, excludes]);
  const itemsSetKeywords = useMemo(
    () => new Set([...keywordsIncludes, ...keywordsExcludes]),
    [keywordsExcludes, keywordsIncludes]
  );

  useEffect(() => {
    refetchMetacategories();
  }, [refetchMetacategories]);

  const handleChange = useCallback(
    (values: string[], type: AllowBlockType, category: MetacategoryGroupType, op?: CriteriaValue) => {
      const updatedParams = clonedTargetingParams;

      switch (category) {
        case MetacategoryGroup.ADDITIONAL_OPENX_CATEGORIES:
          if (type === AllowBlockType.ALLOW) {
            if (!values.length) {
              updatedParams.includes = null;
              break;
            }

            updatedParams.includes!.val = values;
            updatedParams.includes!.op = op || Criteria.ANY;
            break;
          }
          updatedParams.excludes = values;
          break;
        case MetacategoryGroup.KEYWORDS:
          if (type === AllowBlockType.ALLOW) {
            updatedParams.keywords.includes = values;
            break;
          }
          updatedParams.keywords.excludes = values;
          break;
      }

      onChange(updatedParams);
    },
    [onChange, clonedTargetingParams]
  );

  const handleSwitchChange = useCallback(() => {
    const newOperator = inter_dimension_operator === Criteria.ALL ? Criteria.ANY : Criteria.ALL;
    onChange({ ...clonedTargetingParams, inter_dimension_operator: newOperator });
  }, [inter_dimension_operator, onChange, clonedTargetingParams]);

  if (areMetacategoriesLoading) {
    return <Loader />;
  }

  if (!itemsSet.size && !itemsSetKeywords.size) {
    return <EmptyFilterMessage text={t('No filters applied.')} />;
  }
  const bothCategoriesEnabled = !!(itemsSet.size && itemsSetKeywords.size);
  const switchState = inter_dimension_operator === Criteria.ANY;

  return (
    <Box data-test="sub-section">
      {bothCategoriesEnabled && (
        <Box mt={3}>
          <Switch
            switchState={switchState}
            onSwitchChange={handleSwitchChange}
            switchOffLabel={t('MATCH ALL')}
            switchOnLabel={t('MATCH ANY')}
          />
        </Box>
      )}

      {!!itemsSet.size && (
        <>
          <FiltersTitle
            onClear={() =>
              onChange({
                ...clonedTargetingParams,
                exclude_mfa: false,
                excludes: [],
                includes: null,
              })
            }
            title={t('Additional OpenX Categories [ {count} ]', { count: itemsSet.size })}
            readonly={readonly}
          />

          <MetacategoryAllowBlockItem
            options={metacategories}
            handleChange={(values, types, op) =>
              handleChange(values, types, MetacategoryGroup.ADDITIONAL_OPENX_CATEGORIES, op)
            }
            includes={includes?.val || []}
            op={includes?.op}
            excludes={excludes}
            readonly={readonly}
            dataTest="categories"
          />
        </>
      )}

      {bothCategoriesEnabled && <DimensionChipLine switchState={inter_dimension_operator === Criteria.ANY} />}

      {!!itemsSetKeywords.size && (
        <>
          <FiltersTitle
            onClear={() => onChange({ ...clonedTargetingParams, keywords: { excludes: [], includes: [] } })}
            title={t('Keywords [ {count} ]', { count: itemsSetKeywords.size })}
            readonly={readonly}
          />
          <MetacategoryAllowBlockItem
            // options not required, just display string from value
            options={undefined}
            handleChange={(values, types) => handleChange(values, types, MetacategoryGroup.KEYWORDS)}
            includes={keywordsIncludes}
            excludes={keywordsExcludes}
            readonly={readonly}
            dataTest="keywords"
          />
        </>
      )}
    </Box>
  );
};
